import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteFerie, getFerie } from '../../redux/features/ferie/ferie.thunk';

import './tabella-ferie.styles.scss';
import { useLazyGetExcelFerieQuery } from '../../redux/features/features.query';
import { baseAPI } from '../../config';
import { Link } from 'react-router-dom';

const TabellaFerie = ({ ferie }) => {

	const dispatch = useDispatch();

	const [getExcelFerie] = useLazyGetExcelFerieQuery();

	const downloadFerieExcel = () => {
		getExcelFerie().unwrap().then((res) => {
			window.open(`${baseAPI}../api/public/storage/export/${res.filename}`, '_self', 'noreferrer');
		}).catch((err) => {
			//toast.error();
		});
	}

	const del = async (id) => {

		if (window.confirm("Sei sicuro di voler eliminare questo permesso?")) {
			const result = await dispatch(deleteFerie(id));

			if (result.error) {
				toast.error(result.payload);
			}
			else {
				toast.success("Ferie o Permesso eliminato con successo");
				dispatch(getFerie(1));
			}
		}
	}

	return (

		<div className="tabella-ferie">
			<div className="row">
				<div className="col-md-11 text-end">
					<Link to='/ferie/new' className="btn btn-info btn-sm btn-block">Aggiungi</Link>
				</div>
				<div className="col-md-1 text-end">
					<button onClick={() => downloadFerieExcel()} className="btn-success btn btn-sm text-black">Scarica Excel</button>
				</div>
			</div>
			<br />
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<p className="text-primary font-14"><strong>Lista Ferie e Permessi</strong>
							</p>
							<hr />
							<table className="table table-striped mb-0">
								<thead className="table-dark">
									<tr>
										<th scope="col">Cognome</th>
										<th scope="col">Nome</th>
										<th scope="col">Codice Tipologia</th>
										<th scope="col">Tipologia Ferie</th>
										<th className="text-center" scope="col">dal</th>
										<th className="text-center" scope="col">al</th>
										<th className="text-center" scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{
										(ferie && ferie.length > 0) ?
											ferie.map((ferie) => (
												<tr key={ferie.id}>
													<td>{ferie.dipendente.cognome}</td>
													<td>{ferie.dipendente.nome}</td>
													<td>{ferie.cod_tipologia}</td>
													<td>{ferie.tipologia}</td>
													<td className="text-center">{ferie.dal}</td>
													<td className="text-center">{ferie.al}</td>
													<td>
														<button to="#ferie" onClick={() => del(ferie.id)} className="btn btn-danger" title='Elimina Ferie/Permesso'><i className="uil uil-times-square"></i></button> &nbsp;
													</td>
												</tr>
											))
											: (<tr>
												<td colSpan='6'>Nessun elemento trovato</td>
											</tr>)
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
};

export default TabellaFerie;

