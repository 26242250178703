import { baseAPI } from "../../config"

export const login = async (user, pass) => {
    return fetch(baseAPI + "login", {
        method: "POST",
        cache: "no-store",
        body: JSON.stringify({
            user,
            pass
        })
    }).then(response => response.json())
}