import React from 'react';
import { useState } from 'react';

import './aggiungi-dipendente.styles.scss';

const AggiungiDipendente = ({ collectChange, addDipendente, elencoDipendenti, elencoAutomezzi, errori: { id_dipendente } }) => {


	const [elencoCampi, setElencoCampi] = useState([1]);

	const { automezzi } = elencoAutomezzi;
	const { dipendenti } = elencoDipendenti;

	const addCampo = () => {
		setElencoCampi(campo => [...campo, Number(campo.pop() + 1)]);
		addDipendente();
	}

	return (
		<div className="aggiungi-dipendente">
			{
				elencoCampi.map(campo => (
					<div className="row" key={campo}>
						<div className="col-lg-6">
							<div className="mb-3">
								<label htmlFor="id_automezzo" className="form-label">Automezzo</label>
								<select className={`form-select`} onChange={collectChange} name={`id_automezzo_${campo}`}>
									<option value="0">Seleziona</option>
									{
										automezzi.map((val) => (
											<option key={val.id} value={val.id}>{val.nome} &lt;{val.targa}&gt;</option>
										))
									}
								</select>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="mb-3">
								<label htmlFor="id_dipendente" className="form-label">Dipendente</label>
								<select required className={`form-select ${campo === 1 ? (id_dipendente ? 'is-invalid' : '') : ''}`} defaultValue={elencoDipendenti ? elencoDipendenti : ''} onChange={collectChange} name={`id_dipendente_${campo}`}>
									<option value="0">Seleziona</option>
									{
										dipendenti.map((val) => (
											<option key={val.id} value={val.id}>{val.nome_breve}</option>
										))
									}
								</select>
								<div className="invalid-feedback">
									Inserire almeno un dipendente per il turno
								</div>
							</div>
						</div>
					</div>
				))
			}
			<div className="col-lg-12 text-center">
				<div className="mb-3">
					<label className="form-label">&nbsp;</label>
					<button type="button" onClick={addCampo} className="btn btn-success mt-30"><i className="uil uil-plus"></i> Aggiungi Dipendente</button>
				</div>
			</div>
		</div>
	);
};

export default AggiungiDipendente;