import React from 'react';

import './scegli-giorno.styles.scss';

const ScegliGiorno = ({ collectChange, errori, giorno }) => {

	
	return (
		<div className="scegli-giorno">
			<div className="row">
				<div className="col-lg-4"></div>
				<div className="col-lg-4">
					<div className="mb-3">
						<label htmlFor="giorno" className="form-label">Giorno</label>
						<input className={`form-control ${errori.giorno ? 'is-invalid' : null}`} onChange={collectChange} id="giorno" type="date" name="giorno" defaultValue={giorno ? giorno : ''} />
						<div className="invalid-feedback">
							Inserire un giorno per il turno
						</div>
					</div>
				</div>
				<div className="col-lg-4"></div>
			</div>
		</div>
	);
};

export default ScegliGiorno;