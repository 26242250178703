import React from 'react';
import { Link } from 'react-router-dom';

import './form-automezzi.styles.scss';

const FormAutomezzi = ({ errori, handleChange, categoria_automezzo, targa, tipologia, attivo, disponibilita, mc, portata, scadenza_leasingnoleggio, albo_rifiuti, crono_tachigrafo, note, inviaDati }) => {

	

	//console.log(albo_rifiuti);

	return (
		<div className="form-automezzi">
			<form action="" id="" method="post">
				<div className="row">
					<div className="col-lg-4">
						<label htmlFor="categoria_automezzo" className="form-label">Categoria Automezzo *</label>
						<input className={`form-control ${errori.categoria ? 'is-invalid' : null}`} id="categoria_automezzo" onChange={handleChange} type="text" name="categoria_automezzo" value={categoria_automezzo || ''} required />
						<div className="invalid-feedback">
							Inserire un categoria per l'automezzo
						</div>
					</div>
					<div className="col-lg-4">
						<div className="mb-3">
							<label htmlFor="targa" className="form-label">Targa *</label>
							<input className={`form-control ${errori.targa ? 'is-invalid' : null}`} maxLength='10' id="targa" onChange={handleChange} type="text" name="targa" value={targa || ''} required />
							<div className="invalid-feedback">
								Inserire una targa per l'automezzo
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="mb-3">
							<label htmlFor="attivo" className="form-label">Stato Automezzo *</label>
							<select className="form-select" id="attivo" onChange={handleChange} defaultValue={attivo} name="attivo">
								<option value="0">Seleziona</option>
								<option value="1">ATTIVO</option>
								<option value="2">NON ATTIVO</option>
							</select>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-3">
						<div className="mb-3">
							<label htmlFor="disponibilita" className="form-label">Disponibilità</label>
							<select className="form-select" onChange={handleChange} id="disponibilita" defaultValue={disponibilita} name="disponibilita">
								<option value="0">Seleziona</option>
								<option value="1">PROPRIETA'</option>
								<option value="2">NOLEGGIO</option>
								<option value="3">LEASING</option>
							</select>
						</div>
					</div>
					{
						(disponibilita === 2 || disponibilita === 3) ? (
							<div className="col-lg-3">
								<label htmlFor="scadenza_leasingnoleggio" className="form-label">Data Scadenza {disponibilita === 2 ? 'Noleggio' : null}{disponibilita === 3 ? 'Leasing' : null}</label>
								<input className="form-control" onChange={handleChange} id="scadenza_leasingnoleggio" type="date" name="scadenza_leasingnoleggio" value={scadenza_leasingnoleggio || ''}/>
							</div>
						) : null
					}

					<div className="col-lg-3">
						<label htmlFor="albo_rifiuti" className="form-label">Iscrizione Albo Gestione Rifiuti</label>
						<select className="form-select" onChange={handleChange} defaultValue={albo_rifiuti} id="albo_rifiuti" name="albo_rifiuti">
							<option value="0">Seleziona</option>
							<option value="1">SI</option>
							<option value="2">NO</option>
						</select>
					</div>
					<div className="col-lg-3">
						<label htmlFor="crono_tachigrafo" className="form-label">Obbligo di Crono Tachigrafo</label>
						<select className="form-select" onChange={handleChange} defaultValue={crono_tachigrafo} id="crono_tachigrafo" name="crono_tachigrafo">
							<option value="0">Seleziona</option>
							<option value="1">SI</option>
							<option value="2">NO</option>
						</select>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-4">
						<label htmlFor="tipologia" className="form-label">Tipologia</label>
						<input className="form-control" id="tipologia" onChange={handleChange} type="text" name="tipologia" value={tipologia || ''} />
					</div>
					<div className="col-lg-4">
						<label htmlFor="mc" className="form-label">Metri Cubi</label>
						<input className="form-control" id="mc" onChange={handleChange} type="text" name="mc" value={mc || ''} />
					</div>
					<div className="col-lg-4">
						<div className="mb-3">
							<label htmlFor="portata" className="form-label">Portata</label>
							<input className="form-control" onChange={handleChange} id="portata" type="text" name="portata" value={portata || ''} />
						</div>
					</div>
				</div>


				<div className="row">
					<div className="col-lg-12">
						<label htmlFor="Note" className="form-label">Note</label>
						<textarea className='form-control' id="note" name='note' onChange={handleChange} value={note || ''}></textarea>
					</div>
				</div>


				<br />
				<br />



				<div className="row">
					<div className="col-lg-4"></div>
					<div className="col-lg-4 text-center">
						<Link to="/automezzi" className="btn btn-danger">Torna Indietro</Link> &nbsp;&nbsp;
						<button type='button' onClick={inviaDati} className="btn btn-success">Salva Dati</button>
					</div>
					<div className="col-lg-4"></div>

				</div>
			</form>
		</div>
	);
};

export default FormAutomezzi;