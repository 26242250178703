import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './menu-bar.styles.scss';

const MenuBar = () => {

	const { pathname } = useLocation();

	return (
		<div className="leftside-menu">


			<Link to="/" className="logo logo-light">

				<h1><img src="assets/images/logo_akrea.png" alt="Logo versione chiara" /></h1>

			</Link>


			<Link to="/" className="logo logo-dark">
				<h1><img src="assets/images/logo_akrea.png" alt="Logo versione chiara" /></h1>
			</Link>


			<button type="button" className="btn button-sm-hover p-0" data-bs-toggle="tooltip" data-bs-placement="right" title="Show Full Sidebar">
				<i className="ri-checkbox-blank-circle-line align-middle"></i>
			</button>


			<div className="h-100" id="leftside-menu-container" data-simplebar>


				<ul className="side-nav">
					<br />
					<li className={`side-nav-item ${pathname === '/' ? 'active' : null}`}>
						<Link to="/" className="side-nav-link">
							<i className="uil-calender"></i>
							<span> Calendario Attivita'</span>
						</Link>
					</li>

					<li className="side-nav-title side-nav-item">Gestione Turni</li>

					<li className={`side-nav-item ${pathname === '/turni' ? 'active' : null}`}>
						<Link to="/turni" className="side-nav-link">
							<i className="uil-calendar-alt"></i>
							<span> Lista Turnazione </span>
						</Link>
					</li>

					<li className={`side-nav-item ${pathname === '/servizi' ? 'active' : null}`}>
						<Link to="/servizi" className="side-nav-link">
							<i className="uil-file-alt"></i>
							<span> Gestione Servizi </span>
						</Link>
					</li>

					<li className={`side-nav-item ${pathname === '/attivita' ? 'active' : null}`}>
						<Link to="/attivita" className="side-nav-link">
							<i className="uil-file-alt"></i>
							<span> Gestione Attivita' </span>
						</Link>
					</li>

					<li className="side-nav-title side-nav-item">Gestione Dipendenti</li>

					<li className={`side-nav-item ${pathname === '/dipendenti' ? 'active' : null}`}>
						<Link to="/dipendenti" className="side-nav-link">
							<i className="uil-users-alt"></i>
							<span> Lista Dipendenti </span>
						</Link>
					</li>

					<li className={`side-nav-item ${pathname.substring(0, 10) === '/automezzi' ? 'active' : null}`}>
						<Link to="/automezzi" className="side-nav-link">
							<i className="uil-truck"></i>
							<span> Automezzi </span>
						</Link>
					</li>

					<li className={`side-nav-item ${pathname.substring(0, 10) === '/consuntivo' ? 'active' : null}`}>
						<Link to="/consuntivo" className="side-nav-link">
							<i className="uil-file-alt"></i>
							<span> Gestione Consuntivo </span>
						</Link>
					</li>

					<li className="side-nav-title side-nav-item">Gestione Permessi e Ferie</li>

					<li className={`side-nav-item ${pathname === '/ferie' ? 'active' : null}`}>
						<Link to="/ferie" className="side-nav-link">
							<i className="uil-copy-alt"></i>
							<span> Lista Permessi e Ferie </span>
						</Link>
					</li>

					<li className="side-nav-title side-nav-item"></li>
					<li className="side-nav-title side-nav-item"></li>
					<li className="side-nav-title side-nav-item"></li>
					<li className="side-nav-title side-nav-item"></li>
					<li className="side-nav-title side-nav-item"></li>
					<li className="copymenu"><img class="gerium-img" src="assets/images/logo-white.png" alt="Logo versione chiara" /></li>


				</ul>




				<div className="clearfix"></div>
			</div>


		</div>
	);
};

export default MenuBar;