import { createSlice } from "@reduxjs/toolkit";
import { setLogin } from "./auth.thunk";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        error: null,
        loading: false
    },
    reducers: {
        svuotaErrore: (state) => {
            state.error = null;
        },
        logout: (state) => {
            state.error = null
            state.loading = false;
            state.user = null;
        }
    },
    extraReducers: {
        [setLogin.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        [setLogin.fulfilled]: (state, action) => {
            state.loading = false;
            state.user = action.payload.user;
            state.error = null;
        },
        [setLogin.rejected]: (state, action) => {
            state.loading = false;
            state.user = null;
            state.error = "Errore: " + action.payload;
        }
    }
});

export const { svuotaErrore, logout } = authSlice.actions;

export default authSlice.reducer;