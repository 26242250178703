import { baseAPI } from "../../../config";

export const ferie = async (token, pagina) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "ferie-" + pagina, {
        cache: 'no-cache',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const ferieAdd = async (token, infoFerie) => {
    return fetch(baseAPI + "ferie", {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
            id_dipendente: infoFerie.id_dipendente,
            id_tipo: infoFerie.id_tipo,
            dal: infoFerie.dal,
            al: infoFerie.al
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const ferieDelete = async (token, id) => {
    return fetch(baseAPI + "ferie/" + id, {
        method: 'DELETE',
        cache: 'no-cache',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const ferieSearch = async (token, pagina, ricerca) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "ferie/search-" + pagina + '?' + new URLSearchParams(ricerca), {
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        
    }).then(response => response.json());
}