import { createAsyncThunk } from "@reduxjs/toolkit";
import { turni, turniAdd, turniDelete, turniEdit, turniSearch } from "./turni.api";
import { logout } from "../../auth/auth.slice";

export const addTurno = createAsyncThunk(
    'features/addTurno',
    async (infoTurno, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await turniAdd(stato.auth.user.token, infoTurno);
        if (response.esito === 1) {
            return response;
        }
        else if(response.esito === 0 && response.errorCode === 2)
        {
            return rejectWithValue('Turno gia esistente');
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

export const editTurno = createAsyncThunk(
    'features/editTurno',
    async (infoTurno, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await turniEdit(stato.auth.user.token, infoTurno);
        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

/*export const getAutomezzo = createAsyncThunk(
    'features/getAutomezzo',
    async (id, { getState, rejectWithValue }) => {

        const stato = getState();
        const response = await automezziSingle(stato.auth.user.token, id);

        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)*/

export const getTurni = createAsyncThunk(
    'features/turni',
    async (pagina, { getState, rejectWithValue, dispatch }) => {

        const stato = getState();
        const response = await turni(stato.auth.user.token, pagina); 

        if (response.esito === 1) {
            return response;
        }
        else {
            if(response.code === 401)
            {
                dispatch(logout());
            }
            return rejectWithValue(response.error);
        }
    }
)


export const deleteTurno = createAsyncThunk(
    'features/deleteTurno',
    async (id, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await turniDelete(stato.auth.user.token, id);
        if (response.esito === 1) {
            return response;
        }
        else if (response.esito === 2) {
            return rejectWithValue("Impossibile eliminare il turno");
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

export const searchTurni = createAsyncThunk(
    'features/searchTurni',
    async (info, { getState, rejectWithValue, dispatch }) => {

        const stato = getState();
        const response = await turniSearch(stato.auth.user.token, info.pagina, info.ricerca);

        if (response.esito === 1) {
            return response;
        }
        else {
            if(response.code === 401)
            {
                dispatch(logout());
            }
            return rejectWithValue(response.error);
        }
    }
)