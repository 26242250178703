import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MenuBar from '../../components/menu-bar/menu-bar.component';
import TopBar from '../../components/top-bar/top-bar.component';
import Home from '../home/home.component';
import Servizi from '../servizi/servizi.component';
import NuovoServizio from '../servizi/nuovo-servizio/nuovo-servizio.component';
import Attivita from '../attivita/attivita.component';
import NuovaAttivita from '../attivita/nuova-attivita/nuova-attivita.component';
import NuovoTurno from '../turni/nuovo-turno/nuovo-turno.component';
import Turni from '../turni/turni.component';
import Comunicazioni from '../comunicazioni/comunicazioni.component';
import NuovaComunicazione from '../nuova-comunicazione/nuova-comunicazione.component';
import NuovoDipendente from '../lista-dipendenti/nuovo-dipendente/nuovo-dipendente.component';
import EditDipendente from '../lista-dipendenti/edit-dipendente/edit-dipendente.component';
import NuovoAutomezzo from '../automezzi/nuovo-automezzo/nuovo-automezzo.component';
import EditAutomezzo from '../automezzi/edit-automezzo/edit-automezzo.component';
import ListaDipendenti from '../lista-dipendenti/lista-dipendenti.component';
import FerieDipendenti from '../ferie/ferie.component';
import NuovoFerieDipendenti from '../ferie/aggiungi-ferie/aggiungi-ferie.component';
import ListaAutomezzi from '../automezzi/automezzi.component';
import StandardPage from '../../components/standard-page/standard-page.component';
import Personal from '../personal/personal.component';
import LeggiComunicazione from '../leggi-comunicazione/leggi-comunicazione.component';
import EditTurno from '../turni/ediìt-turno/edit-turno.component';
import ListaConsuntivo from '../consuntivo/consuntivo.component';
import NuovoConsuntivo from '../consuntivo/aggiungi-consuntivo/aggiungi-consuntivo.component';

import './wrapper.styles.scss';

const FerieDipendentiPage = StandardPage(FerieDipendenti);
const NuovoFerieDipendentiPage = StandardPage(NuovoFerieDipendenti);
const NuovoConsuntivoPage = StandardPage(NuovoConsuntivo);
const ListaConsuntivoPage = StandardPage(ListaConsuntivo);
const ListaDipendentiPage = StandardPage(ListaDipendenti);
const NuovoDipendentePage = StandardPage(NuovoDipendente);
const EditDipendentePage = StandardPage(EditDipendente);
const TurniPage = StandardPage(Turni);
const NuovoTurnoPage = StandardPage(NuovoTurno);
const EditTurnoPage = StandardPage(EditTurno);
const ServiziPage = StandardPage(Servizi);
const NuovoServizioPage = StandardPage(NuovoServizio);
const AttivitaPage = StandardPage(Attivita);
const NuovaAttivitaPage = StandardPage(NuovaAttivita);
const AutomezziPage = StandardPage(ListaAutomezzi);
const NuovoAutomezzoPage = StandardPage(NuovoAutomezzo);
const EditAutomezzoPage = StandardPage(EditAutomezzo);
 
const Wrapper = () => {
	return (
		<div className="wrapper">
			<TopBar />
			<MenuBar />
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/servizi' element={<ServiziPage titolo="Elenco Servizi" />} />
				<Route path='/servizi/new' element={<NuovoServizioPage titolo="Nuovo Servizio" />} />
				<Route path='/attivita' element={<AttivitaPage titolo="Elenco Attivita'" />} />
				<Route path='/attivita/new' element={<NuovaAttivitaPage titolo="Nuova Attivita'" />} />
				<Route path='/turni' element={<TurniPage titolo="Elenco Turni" />} />
				<Route path='/turni/new' element={<NuovoTurnoPage titolo="Nuovo Turno" />} />
				<Route path='/turni/edit/:id' element={<EditTurnoPage titolo="Modifica Turno" />} />
				<Route path='/automezzi' element={<AutomezziPage titolo="Elenco Automezzi" />} />		
				<Route path='/automezzi/new' element={<NuovoAutomezzoPage titolo="Nuovo Automezzo"/>} />	
				<Route path='/automezzi/edit/:id' element={<EditAutomezzoPage titolo="Modifica Automezzo"/>} />	
				<Route path='/dipendenti' element={<ListaDipendentiPage titolo="Elenco Dipendenti" />} />	
				<Route path='/ferie' element={<FerieDipendentiPage titolo="Permessi e Ferie Dipendenti" />} />	
				<Route path='/ferie/new' element={<NuovoFerieDipendentiPage titolo="Inserisci Permessi e Ferie Dipendenti" />} />	
				<Route path='/dipendenti/edit/:id' element={<EditDipendentePage titolo="Modifica Dipendente" />} />	
				<Route path='/dipendenti/new' element={<NuovoDipendentePage titolo="Nuovo Dipendente" />} />
				<Route path='/personal' element={<Personal />} />
				<Route path='/comunicazioni' element={<Comunicazioni />} />		
				<Route path='/leggi-comunicazione' element={<LeggiComunicazione />} />	
				<Route path='/nuova-comunicaziosne' element={<NuovaComunicazione />} />
				<Route path='/consuntivo' element={<ListaConsuntivoPage titolo="Elenco Ore Dipendenti" />} />	
				<Route path='/consuntivo/new' element={<NuovoConsuntivoPage titolo="Aggiungi Ore Dipendenti" />} />	
			</Routes>
		</div>
	);
};

export default Wrapper;