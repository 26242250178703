import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component';
import { selectError, selectLoading } from '../../../redux/features/features.selectors';
import { addAttivita } from '../../../redux/features/attivita/attivita.thunk';

import './nuova-attivita.styles.scss';
import FormAttivita from '../../../components/form-attivita/form-attivita.component';
import { toast } from 'react-toastify';

const NuovaAttivita = () => {

    const dispatch = useDispatch();
	const navigate = useNavigate();
	const loading = useSelector(selectLoading);
	const error = useSelector(selectError);

	const [campi, setCampi] = useState({
		id: 0,
		codice: '',
		attivita: ''
	});

	const [errori, setErrori] = useState({
		codice: false,
		attivita: false
	});


	const handleChange = (e) => {
		const { value, name } = e.target;
		setCampi({ ...campi, [name]: value });
		setErrori({ codice: false, attivita: false });
	}

	const inviaDati = async (e) => {
		e.preventDefault();

		if (campi.codice === '') return setErrori({ ...errori, codice: true });
		if (campi.attivita === '') return setErrori({ ...errori, attivita: true });

		const result = await dispatch(addAttivita(campi));

		if (!result.error) {
			toast.success('Attivita inserito con successo', {
				position: 'top-right'
			})
			navigate('/attivita');
		} else {
			toast.error(result.payload, {
				position: 'top-right'
			})
		}
	}

	return (loading) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		<div className="nuova-attivita">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
                        {
								(error) ?
									(<div className="alert alert-danger" role="alert">
										{error}
									</div>)
									: null
							}
							<FormAttivita errori={errori} handleChange={handleChange} {...campi} inviaDati={inviaDati} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NuovaAttivita;