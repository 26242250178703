import { createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "./auth.api";

export const setLogin = createAsyncThunk(
    'auth/login',
    async ({ user, pass }, { rejectWithValue }) => {
        const response = await login(user, pass);

        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)