import React from 'react';
import { Link } from 'react-router-dom';

import './leggi-comunicazione.styles.scss';

const LeggiComunicazione = () => {
	return (
		<div className="leggi-comunicazione">
			<div className="content-page">
                <div className="content">

                  
                    <div className="container-fluid">
                        
                
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box">
									<div className="page-title-right">
                                        <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#compose-modal">Nuova comunicazione</button>
                                    </div>
									
                                    <h4 className="page-title">Comunicazioni</h4>
                                </div>
                            </div>
                        </div>
                     

                        <div className="row">

                        
                            <div className="col-12">
								
                                <div className="card">
                                    <div className="card-body">

                                            <div className="mt-3">
                                                <h5 className="font-18">Your elite author Graphic Optimization reward is ready!</h5>

                                                <hr />

                                                <div className="d-flex mb-3 mt-1">
                                                    <div className="w-100 overflow-hidden">
                                                        <small className="float-end">Dec 14, 2017, 5:17 AM</small>
                                                        <small className="text-muted">Da: Fabio Cappellieri</small>
                                                    </div>
                                                </div>

                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                

                                                
                                               
                                                
                                                <div className="mt-5">
												 <hr />
                                                    <Link to="/nuova-comunicazione" className="btn btn-secondary me-2"><i className="mdi mdi-reply me-1"></i> Rispondi</Link>
                                                    <Link to="/comunicazioni" className="btn btn-danger me-2">Torna Indietro</Link>
                                                </div>
     
                                            </div>
                                    
                                    </div>
                               
                                    <div className="clearfix"></div>
                                </div>

                            </div>
                        </div>
                        
                    </div> 

                </div> 


               



            </div>
		</div>
	);
};

export default LeggiComunicazione;