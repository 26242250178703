import { createSelector } from 'reselect';

export const selectFeatures = (state) => state.features;
export const selectError = (state) => state.features.error;
export const selectLoading = (state) => state.features.loading;
export const selectDati = createSelector(
  [selectFeatures],
  (features) => features.dati
);

export const selectTurni = createSelector(
  [selectDati],
  (dati) => (dati ? dati.turni : [])
);

export const selectDipendenti = createSelector(
  [selectDati],
  (dati) => (dati ? dati.dipendenti : [])
);

export const selectAutomezzi = createSelector(
  [selectDati],
  (dati) => (dati ? dati.automezzi : [])
);

export const selectServizi = createSelector(
  [selectDati],
  (dati) => (dati ? dati.servizi : [])
);

export const selectAttivita = createSelector(
  [selectDati],
  (dati) => (dati ? dati.attivita : [])
);

export const selectFerie = createSelector(
  [selectDati],
  (dati) => (dati ? dati.ferie : [])
);

export const selectConsuntivo = createSelector(
  [selectDati],
  (dati) => (dati ? dati.consuntivo : [])
);

// Funzione per selezionare l'utente
export const selectUser = (state) => state.features.user;