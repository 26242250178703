export const getPagination = (pagina, pagine, num) => {

    if(!pagina || !pagine) return {
        before: [],
        after: [],
        first: null,
        last: null
    };

    const before = [];
	const after = [];

    for (let i = (pagina - num); i < (pagina) ; i++) {
        if (i > 0) before.push(i);
    }

    for (let i = pagina + 1; i < (pagina + num); i++) {
        if (i <= pagine) after.push(i);
    }

    return {
        before, 
        after,
        first: pagina > 1 ? pagina - 1 : null,
        last: pagina < pagine ? pagina + 1 : null
    };
}

export const getNumberFromGiorno = (giorno) => {
    const giorni = {
        lunedi: 1,
        martedi: 2, 
        mercoledi: 3,
        giovedi: 4,
        venerdi: 5,
        sabato: 6,
        domenica: 7
    };

    return giorni[giorno];
}

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);