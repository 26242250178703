import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/loader/loader.component';
import { selectLoading, selectTurni } from '../../redux/features/features.selectors';
import { searchTurni } from '../../redux/features/turni/turni.thunk';
import { capitalizeFirstLetter } from '../../utilities/utility';

import './home.styles.scss';

const Home = () => {

	const dispatch = useDispatch();
	const turni = useSelector(selectTurni);
	const loading = useSelector(selectLoading);

	const [giorno, setGiorno] = useState(new Date());
	const [tipoTurno, setTipoTurno] = useState(0);

	const changeDay = (day) => {
		if (day > 0) {
			setGiorno(moment(giorno).add(1, 'day').toDate());
		}
		else {
			setGiorno(moment(giorno).subtract(1, 'day').toDate());
		}
	}

	const handleType = (tipo) => {
		if (tipoTurno === tipo) {
			setTipoTurno(0);
		}
		else {
			setTipoTurno(tipo);
		}
	}

	useEffect(() => {
		dispatch(searchTurni({
			pagina: 0,
			ricerca: {
				dal: giorno.toISOString().split('T')[0],
				al: giorno.toISOString().split('T')[0],
				turnazione: tipoTurno
			}
		}));
	}, [dispatch, giorno, tipoTurno]);



	return (loading) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		<div className="content-page">
			<div className="content">


				<div className="container-fluid">

					<div className="row">
						<div className="col-8">
							<div className="page-title-box">
								<h4 className="page-title">Calendario Attivita'</h4>
							</div>
						</div>
						<div className="col-4">
							<div className="page-title-box text-end">
								<h4 className="page-title">
									{(tipoTurno === 0) ? (<strong className="text-primary">TURNI DEL GIORNO</strong>) : null}
									{(tipoTurno === 1) ? (<strong className="text-primary">TURNI RACCOLTA DIFFERENZIATA</strong>) : null}
									{(tipoTurno === 2) ? (<strong className="text-primary">PRIMO TURNO</strong>) : null}
									{(tipoTurno === 3) ? (<strong className="text-primary">TURNO POMERIDIANO</strong>) : null}
								</h4>
							</div>
						</div>
					</div>


					<div className="row">
						<div className="col-lg-2">
							<div className='pointer' onClick={() => changeDay(-1)}>
								<div className="card border-danger border">
									<div style={{ paddingBottom: "30px" }} className="card-body text-center">
										<h5 className="card-title text-danger"><br />Giorno Precedente</h5>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="card text-white bg-warning">
								<div className="card-body text-center">
									<h2>{(giorno != null) ? capitalizeFirstLetter(giorno.toLocaleDateString('it-IT', { weekday: 'long' })) + ', ' + giorno.toLocaleDateString('it-IT', { year: 'numeric', month: 'long', day: 'numeric' }) : null}</h2>
								</div>
							</div>
						</div>
						<div className="col-lg-2">
							<div className='pointer' onClick={() => changeDay(1)}>
								<div className="card border-success border">
									<div style={{ paddingBottom: "30px" }} className="card-body text-center">
										<h5 className="card-title text-success"><br />Giorno Successivo </h5>
									</div>
								</div>
							</div>
						</div>
					</div>

					<br />

					<div className="row">
						<div className="col-lg-12 text-center">
							<button type='button' onClick={() => handleType(1)} className={`btn btn-${tipoTurno === 1 ? 'success' : 'light'}`}>Raccolta differenziata</button> &nbsp;
							<button type='button' onClick={() => handleType(2)} className={`btn btn-${tipoTurno === 2 ? 'success' : 'light'}`}>Primo turno</button> &nbsp;
							<button type='button' onClick={() => handleType(3)} className={`btn btn-${tipoTurno === 3 ? 'success' : 'light'}`}>Turno pomeridiano</button>
						</div>
					</div>

					<hr />
					<br />

					<div className="row">
						<div className="col-lg-12">
							<table className="table table-striped mb-0">
								<thead className="table-dark">
									<tr>
										<th scope="col">Servizio</th>
										<th scope="col">Attivita'</th>
										<th className="text-center" scope="col">Data</th>
										<th className="text-center" scope="col">Ora inizio</th>
										<th className="text-center" scope="col">Ora fine</th>
										<th className="text-center" scope="col">Automezzo</th>
										<th className="text-center" scope="col" colSpan="3">Dipendenti</th>
									</tr>
								</thead>
								<tbody>
									{
										(turni) ?
											turni.map((turno) => (
												<tr key={turno.id + " " + turno.otherViewInfo.targaAutomezzo}>
													<th scope="row">{turno.servizio}</th>
													<td>{turno.cod_attivita} - {turno.attivita}</td>
													<td className="text-center">{new Date(turno.giorno).toLocaleDateString('it-IT', { year: "numeric", month: "numeric", day: "numeric" })}</td>
													<td className="text-center">{turno.oraInizio}</td>
													<td className="text-center">{turno.oraFine}</td>
													<td className="text-center">{(turno.otherViewInfo.targaAutomezzo !== '') ? turno.otherViewInfo.targaAutomezzo : 'NESSUNO'}</td>
													<td colSpan="3">
														{
															turno.otherViewInfo.listaDipendenti.map((val) => {
																return (
																	<span key={val} className="badge badge-outline-dark" style={{ marginRight: 15 }}>{val}</span>
																)
															})
														}
													</td>
												</tr>
											)) : null
									}
								</tbody>
							</table>

						</div>
					</div>

				</div>

			</div>
			<footer className="footer">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-6">
							© Copyright 2023 - <strong className="text-primary">Akrea S.p.a.</strong>
						</div>
						<div className="col-md-6">
							<div className="text-md-end footer-links d-none d-md-block">
								<a href="http://">developed by <strong className="text-primary">Kromix Soc. Coop.</strong></a>
							</div>
						</div>
					</div>
				</div>
			</footer>

		</div>
	);
};

export default Home;