import { baseAPI } from "../../../config";

export const turni = async (token, pagina) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "turni-" + pagina, {
        cache: 'no-cache',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}


export const turniAdd = async (token, infoTurni) => {

    let altreVar = {};

    for(const turni in infoTurni) {
        if(turni.substring(0, 12) === 'id_automezzo') altreVar = {...altreVar, [turni]: infoTurni[turni] }
        if(turni.substring(0, 13) === 'id_dipendente') altreVar = {...altreVar, [turni]: infoTurni[turni] }
    }

    return fetch(baseAPI + "turni", {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
            giorno: infoTurni.giorno,
            id_servizio: infoTurni.id_servizio,
            id_attivita: infoTurni.id_attivita,
            ora_inizio: infoTurni.ora_inizio,
            ora_fine: infoTurni.ora_fine,
            ndipendenti: infoTurni.ndipendenti,
            giorni: infoTurni.settimana,
            note: infoTurni.note,
            giornodal: infoTurni.giornodal,
            giornoal: infoTurni.giornoal,
            ...altreVar
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const turniEdit = async (token, infoTurni) => {
    let altreVar = {};

    for(const turni in infoTurni) {
        if(turni.substring(0, 12) === 'id_automezzo') altreVar = {...altreVar, [turni]: infoTurni[turni] }
        if(turni.substring(0, 13) === 'id_dipendente') altreVar = {...altreVar, [turni]: infoTurni[turni] }
    }

    return fetch(baseAPI + "turni/" + infoTurni.id, {
        method: 'PATCH',
        cache: 'no-cache',
        body: JSON.stringify({
            id: infoTurni.id,
            giorno: infoTurni.giorno,
            id_servizio: infoTurni.id_servizio,
            id_attivita: infoTurni.id_attivita,
            ora_inizio: infoTurni.ora_inizio,
            ora_fine: infoTurni.ora_fine,
            id_dipendente: infoTurni.id_dipendente,
            id_automezzo: infoTurni.id_automezzo,
            giorni: infoTurni.settimana,
            note: infoTurni.note,
            ...altreVar
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const turniDelete = async (token, id) => {
    return fetch(baseAPI + "turni/" + id, {
        method: 'DELETE',
        cache: 'no-cache',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const turniSearch = async (token, pagina, ricerca) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "turni/search-" + pagina + '?' + new URLSearchParams(ricerca), {
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        
    }).then(response => response.json());
}