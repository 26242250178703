import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../components/loader/loader.component';
import { useGetInformationsQuery, useGetSingleTurnoQuery } from '../../../redux/features/features.query';
import { selectError } from '../../../redux/features/features.selectors';
import { editTurno } from '../../../redux/features/turni/turni.thunk';
import { getNumberFromGiorno } from '../../../utilities/utility';

import './edit-turno.styles.scss';
import FormTurnimod from '../../../components/form-turnimod/form-turnimod.component';

const EditTurno = () => {

    const error = useSelector(selectError);
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const { data, isFetching} = useGetInformationsQuery({ refetchOnMountOrArgChange: true });
    const { id } = useParams();
    const { data: turnoData, isFetching: isFetchingTurno, isError: isErrorTurno, error: erroreTurno } = useGetSingleTurnoQuery(id, { refetchOnMountOrArgChange: true })

    if(isErrorTurno)
    {
        
        toast.error(erroreTurno.data.error, {
            position: 'top-right'
        })
        navigator('/turni');
    }

    

    const oraInizio = useRef();
    const oraFine = useRef();

    const [campi, setCampi] = useState({
        id: id,
        id_utente: 0,
        giorno: (turnoData) ? turnoData.turno?.giorno : '',
        id_servizio: (turnoData) ? turnoData.turno?.id_servizio : 0,
        id_attivita: (turnoData) ? turnoData.turno?.id_attivita : 0,
        ora_inizio: '',
        ora_fine: '',
        ndipendenti: 1
    });

    const [errori, setErrori] = useState({
        giorno: false,
        id_servizio: false,
        id_attivita: false,
        ora_inizio: false,
        ora_fine: false,
    });

    const collectChange = (e) => {
        const { value, name } = e.target;
        setCampi({ ...campi, [name]: value });
        setErrori({ giorno: false, id_servizio: false, id_attivita: false, ora_inizio: false, ora_fine: false });
    }

    const addDipendente = () => {
        setCampi({ ...campi, ndipendenti: Number(campi.ndipendenti + 1) })
    }

    const selGiorni = (e) => {

        const { checked, name } = e.target;
        const giornoSettimana = getNumberFromGiorno(name);
        const settimana = campi.settimana;

        if (checked) {
            if (!campi.settimana.includes(giornoSettimana)) {
                settimana.push(giornoSettimana);
            }
        }
        else {
            settimana.splice(settimana.indexOf(giornoSettimana), 1);
        }

        setCampi({ ...campi, settimana: settimana })

    }

    const handleInvia = async (e) => {
        e.preventDefault();

        console.log(campi);

        if (campi.giorno === '') return setErrori({ ...errori, giorno: true });
        if (campi.id_servizio === 0) return setErrori({ ...errori, id_servizio: true });
        if (campi.id_attivita === 0) return setErrori({ ...errori, id_attivita: true });
        if (oraInizio.current.value === '') return setErrori({ ...errori, ora_inizio: true });
        if (oraFine.current.value === '') return setErrori({ ...errori, ora_fine: true });
    

        const result = await dispatch(editTurno({...campi, ora_inizio: oraInizio.current.value, ora_fine: oraFine.current.value}));

        if (!result.error) {
            toast.success('Turno modificato con successo', {
                position: 'top-right'
            })
            navigator('/turni');
        } else {
            toast.error(result.payload, {
                position: 'top-right'
            })
        }
    }

    useEffect(() => {
        setCampi(camp => ({
            ...camp,
            giorno: (turnoData) ? turnoData.turno?.giorno : '',
            id_servizio: (turnoData) ? turnoData.turno?.id_servizio : 0,
            id_attivita: (turnoData) ? turnoData.turno?.id_attivita : 0,
            ora_inizio: (turnoData) ? turnoData.turno?.oraInizio : '',
            ora_fine: (turnoData) ? turnoData.turno?.oraFine : '',
            note: (turnoData) ? turnoData.turno?.note : '',
        }))
        
    }, [turnoData])
    

    return (isFetching || isFetchingTurno) ? (
        <div className='attendere'>
            <Loader />
        </div>
    ) : (
        <div className="NuovoTurno">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <FormTurnimod turnoData={turnoData.turno} {...{error, errori, collectChange, oraInizio, oraFine, data, addDipendente, campi, selGiorni, handleInvia}} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditTurno;