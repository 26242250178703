import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormTurni from '../../../components/form-turni/form-turni.component';
import Loader from '../../../components/loader/loader.component';
import { useGetInformationsQuery } from '../../../redux/features/features.query';
import { selectError } from '../../../redux/features/features.selectors';
import { addTurno } from '../../../redux/features/turni/turni.thunk';
import { getNumberFromGiorno } from '../../../utilities/utility';

import './nuovo-turno.styles.scss';

const NuovoTurno = () => {

    const error = useSelector(selectError);
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const { data, isFetching } = useGetInformationsQuery({ refetchOnMountOrArgChange: true });

    const oraInizio = useRef();
    const oraFine = useRef();

    const [campi, setCampi] = useState({
        id: 0,
        id_utente: 0,
        giorno: '',
        id_servizio: 0,
        id_attivita: 0,
        ora_inizio: '',
        ora_fine: '',
        giornodal: '',
        giornoal: '',
        ndipendenti: 1,
        settimana: []
    });

    const [errori, setErrori] = useState({
        giorno: false,
        id_servizio: false,
        id_attivita: false,
        ora_inizio: false,
        ora_fine: false,
        id_dipendente: false,
    });

    const collectChange = (e) => {
        const { value, name } = e.target;
        setCampi({ ...campi, [name]: value });
        setErrori({ giorno: false, id_servizio: false, id_attivita: false, ora_inizio: false, ora_fine: false });
    }

    const addDipendente = () => {
        setCampi({ ...campi, ndipendenti: Number(campi.ndipendenti + 1) })
    }

    const selGiorni = (e) => {

        const { checked, name } = e.target;
        const giornoSettimana = getNumberFromGiorno(name);
        const settimana = campi.settimana;

        if (checked) {
            if (!campi.settimana.includes(giornoSettimana)) {
                settimana.push(giornoSettimana);
            }
        }
        else {
            settimana.splice(settimana.indexOf(giornoSettimana), 1);
        }

        setCampi({ ...campi, settimana: settimana })

    }

    const handleInvia = async (e) => {
        e.preventDefault();
        
        console.log(campi);

        if (campi.giorno === '') return setErrori({ ...errori, giorno: true });
        if (campi.id_servizio === 0) return setErrori({ ...errori, id_servizio: true });
        if (campi.id_attivita === 0) return setErrori({ ...errori, id_attivita: true });
        if (oraInizio.current.value === '') return setErrori({ ...errori, ora_inizio: true });
        if (oraFine.current.value === '') return setErrori({ ...errori, ora_fine: true });
        if (!campi.hasOwnProperty('id_dipendente_1') || campi.id_dipendente_1 === '0') return setErrori({ ...errori, id_dipendente: true });

        

        const result = await dispatch(addTurno({...campi, ora_inizio: oraInizio.current.value, ora_fine: oraFine.current.value}));

        if (!result.error) {
            toast.success('Turno inserito con successo', {
                position: 'top-right'
            })
            navigator('/turni');
        } else {
            toast.error(result.payload, {
                position: 'top-right'
            })
        }
    }




    return (isFetching) ? (
        <div className='attendere'>
            <Loader />
        </div>
    ) : (
        <div className="NuovoTurno">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <FormTurni error={error} errori={errori} collectChange={collectChange} oraInizio={oraInizio} oraFine={oraFine} data={data} addDipendente={addDipendente} campi={campi} selGiorni={selGiorni} handleInvia={handleInvia} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NuovoTurno;