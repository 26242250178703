import { createAsyncThunk } from "@reduxjs/toolkit";
import { automezzi, automezziAdd, automezziDelete, automezziEdit, automezziSearch } from "./automezzi.api";
import { logout } from "../../auth/auth.slice";

export const addAutomezzo = createAsyncThunk(
    'features/addAutomezzo',
    async (infoAutomezzo, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await automezziAdd(stato.auth.user.token, infoAutomezzo);
        if (response.esito === 1) {
            return response;
        }
        else if(response.esito === 0 && response.errorCode === 2)
        {
            return rejectWithValue('Automezzo gia esistente');
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

export const editAutomezzo = createAsyncThunk(
    'features/editAutomezzo',
    async (infoAutomezzo, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await automezziEdit(stato.auth.user.token, infoAutomezzo);
        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

/*export const getAutomezzo = createAsyncThunk(
    'features/getAutomezzo',
    async (id, { getState, rejectWithValue }) => {

        const stato = getState();
        const response = await automezziSingle(stato.auth.user.token, id);

        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)*/

export const getAutomezzi = createAsyncThunk(
    'features/automezzi',
    async (pagina, { getState, rejectWithValue, dispatch }) => {

        const stato = getState();
        const response = await automezzi(stato.auth.user.token, pagina);

        if (response.esito === 1) {
            return response;
        }
        else {
            if(response.code === 401)
            {
                dispatch(logout());
            }
            return rejectWithValue(response.error);
        }
    }
)

export const deleteAutomezzo = createAsyncThunk(
    'features/deleteAutomezzo',
    async (id, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await automezziDelete(stato.auth.user.token, id);
        if (response.esito === 1) {
            return response;
        }
        else if (response.esito === 2) {
            return rejectWithValue("Impossibile eliminare un automezzo utilizzato in almeno un turno");
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

export const searchAutomezzi = createAsyncThunk(
    'features/searchAutomezzi',
    async (info, { getState, rejectWithValue }) => {

        const stato = getState();
        const response = await automezziSearch(stato.auth.user.token, info.pagina, info.ricerca);

        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)