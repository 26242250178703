import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteAutomezzo, getAutomezzi } from '../../redux/features/automezzi/automezzi.thunk';

import './tabella-automezzi.styles.scss';

const TabellaAutomezzi = ({ automezzi }) => {

	const dispatch = useDispatch();

	const del = async (id) => {

		if(window.confirm("Sei sicuro di voler eliminare questo automezzo"))
		{
			const result = await dispatch(deleteAutomezzo(id));

			if(result.error)
			{
				toast.error(result.payload);
			}
			else
			{
				toast.success("Automezzo eliminato con successo");
				dispatch(getAutomezzi(1));
			}
		}
	}

	return (
		<div className="tabella-automezzi">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<table className="table table-striped mb-0" id='automezzi'>
								<thead className="table-dark">
									<tr>
										<th scope="col">Nome</th>
										<th scope="col">Targa</th>
										<th className="text-center" scope="col">Tipologia</th>
										<th className="text-center" scope="col">mc</th>
										<th className="text-center" scope="col">Portata</th>
										<th className="text-center" scope="col">Note</th>
										<th className="text-center" scope="col" colSpan="3"></th>
									</tr>
								</thead>
								<tbody>
									{
										(automezzi && automezzi.length > 0) ?
											automezzi.map((automezzo) => (
												<tr className={`${automezzo.attivo ? '' : 'table-danger'}`} key={automezzo.id}>
													<th scope="row">{automezzo.categoria_automezzo}</th>
													<td>{automezzo.targa}</td>
													<td className="text-center">{automezzo.tipologia}</td>
													<td className="text-center">{automezzo.mc}</td>
													<td className="text-center">{automezzo.portata}</td>
													<td className="text-center">{automezzo.note}</td>
													<td>
														<Link to={`/automezzi/edit/${automezzo.id}`} className="btn btn-warning" title='Modifica automezzo'><i className="uil uil-edit"></i></Link> &nbsp;
														<button type="button" onClick={() => del(automezzo.id)} className="btn btn-danger" title='Elimina automezzo'><i className="uil uil-times-square"></i></button> &nbsp;
														
													</td>
												</tr>
											))
											: (<tr>
												<td colSpan='6'>Nessun elemento trovato</td>
											</tr>)

									}


								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TabellaAutomezzi;