import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteServizi, getServizi } from '../../redux/features/servizi/servizi.thunk';

import './tabella-servizi.styles.scss';

const TabellaServizi = ({ servizi }) => {

	const dispatch = useDispatch();

	const del = async (id) => {

		if(window.confirm("Sei sicuro di voler eliminare questo automezzo"))
		{
			const result = await dispatch(deleteServizi(id));

			if(result.error)
			{
				toast.error(result.payload);
			}
			else
			{
				toast.success("Automezzo eliminato con successo");
				dispatch(getServizi(1));
			}
		}
	}

	return (

		<div className="tabella-servizi">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<table className="table table-striped mb-0">
								<thead className="table-dark">
									<tr>
										<th scope="col">Servizio</th>
										<th width="100" scope="col"></th>
									</tr>
								</thead>
								<tbody>
								{
										(servizi && servizi.length > 0) ?
										servizi.map((servizio) => (
												<tr key={servizio.id}>
													<th scope="row">{servizio.servizio}</th>
													<td>
														<button type="button" onClick={() => del(servizio.id)} className="btn btn-danger" title='Elimina servizio'><i className="uil uil-times-square"></i></button> &nbsp;
													</td>
												</tr>
											))
											: (<tr>
												<td colSpan='6'>Nessun elemento trovato</td>
											</tr>)

									}

								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
};

export default TabellaServizi;