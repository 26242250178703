import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import './tabella-consuntivo.styles.css';
import { useLazyGetExcelConsuntivoQuery } from '../../redux/features/features.query';
import { baseAPI } from '../../config';
import { Link } from 'react-router-dom';
import { deleteConsuntivo, getConsuntivo } from '../../redux/features/consuntivo/consuntivo.thunk';

const TabellaConsuntivo = ({ consuntivo }) => {

	const dispatch = useDispatch();

	const [getExcelConsuntivo] = useLazyGetExcelConsuntivoQuery();

	const downloadConsuntivoExcel = () => {
		getExcelConsuntivo().unwrap().then((res) => {
			window.open(`${baseAPI}../api/public/storage/export/${res.filename}`, '_self', 'noreferrer');
		}).catch((err) => {
			//toast.error();
		});
	}
 
	const del = async (id) => {

		if (window.confirm("Sei sicuro di voler eliminare questo consuntivo?")) {
			const result = await dispatch(deleteConsuntivo(id));

			if (result.error) {
				toast.error(result.payload);
			}
			else {
				toast.success("Consuntivo eliminato con successo");
				dispatch(getConsuntivo(1));
			}
		}
	}

	return (

		<div className="tabella-ferie">
			<div className="row">
				<div className="col-md-11 text-end">
					<Link to='/consuntivo/new' className="btn btn-info btn-sm btn-block">Aggiungi</Link>
				</div>
				<div className="col-md-1 text-end">
					<button onClick={() => downloadConsuntivoExcel()} className="btn-success btn btn-sm text-black">Scarica Excel</button>
				</div>
			</div>
			<br />
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<p className="text-primary font-14"><strong>Lista Consuntivo</strong>
							</p>
							<hr />
							<table className="table table-striped mb-0">
								<thead className="table-dark">
									<tr>
										<th scope="col">Data Presenza</th>
										<th scope="col">Dipendente</th>
										<th scope="col">Ore notturno</th>
										<th scope="col">Ore ordinario</th>
										<th scope="col">Ore straordinario</th>
										<th scope="col">Ora Inizio Straordinario</th>
										<th scope="col">Ora Fine Straordinario</th>
										<th scope="col">Giorni malattia</th>
										<th scope="col">Giorni infortunio</th>
										<th scope="col">Note</th>
										<th className="text-center" scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{
										(consuntivo && consuntivo.length > 0) ?
											consuntivo.map((consuntivo) => (
												<tr key={consuntivo.id}>
													<td>{new Date(consuntivo.data_presenza).toLocaleDateString('it-IT', { year: "numeric", month: "numeric", day: "numeric" })}</td>
													<td>{consuntivo.dipendente}</td>
													<td>{consuntivo.notturno}</td>
													<td>{consuntivo.ordinario}</td>
													<td>{consuntivo.straordinario}</td>
													<td className="text-center">{consuntivo.inizio}</td>
													<td className="text-center">{consuntivo.fine}</td>
													<td className="text-center">{consuntivo.malattia}</td>
													<td className="text-center">{consuntivo.infortunio}</td>
													<td className="text-center">{consuntivo.note}</td>
													<td>
														<button to="#consuntivo" onClick={() => del(consuntivo.id)} className="btn btn-danger" title='Elimina Ferie/Permesso'><i className="uil uil-times-square"></i></button> &nbsp;
													</td>
												</tr>
											))
											: (<tr>
												<td colSpan='11'>Nessun elemento trovato</td>
											</tr>)
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
};

export default TabellaConsuntivo;