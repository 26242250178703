import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/loader/loader.component';
import TabellaDipendenti from '../../components/tabella-dipendenti/tabella-dipendenti.component';
import { getDipendente, searchDipendente } from '../../redux/features/dipendenti/dipendenti.thunk';
import { selectDipendenti, selectDati, selectLoading } from '../../redux/features/features.selectors';
import { getPagination } from '../../utilities/utility';

import './lista-dipendenti.styles.scss';
import { Link } from 'react-router-dom';

const ListaDipendenti = () => {

	const dispatch = useDispatch();
	const dati = useSelector(selectDati);
	const dipendenti = useSelector(selectDipendenti);
	const loading = useSelector(selectLoading);
	const [active, setActive] = useState(1);
	const [search, setSearch] = useState({
		cognome: '',
		nome: '',
		nome_breve: '',
		nascita: '',
		codice_fiscale: '',
		telefono: '',
		mail: '',
		id_mansione: '',
		id_contratto: '',
		data_assunzione: '',
		note: ''
	})

	const { cognome, nome, codice_fiscale } = search;

	const pagine = getPagination(dati?.pagina, dati?.pagine, 4);

	useEffect(() => {
		dispatch(getDipendente(1));
	}, [dispatch]);

	const paginate = (pagina) => {
		setActive(pagina);
		dispatch(getDipendente(pagina));
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
		setSearch({ ...search, [name]: value })
	}

	const handleCerca = async () => {
		await dispatch(searchDipendente({
			pagina: 1,
			ricerca: search
		}))

		//console.log(search);
	}

	return (loading) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		(dati) ? (

			<div className="lista-dipendenti">
				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-body">
								<form action="" id="" method="get">
									<p className="text-primary font-14"><strong>Cerca un dipendente</strong></p>
									<hr />

									<div className="row">
										<div className="col-lg-4">
											<div className="mb-3">
												<label htmlFor="cognome" className="form-label">Cognome</label>
												<input type="text" onChange={handleChange} className="form-control" value={cognome} name="cognome" id="cognome" />
											</div>
										</div>
										<div className="col-lg-4">
											<div className="mb-3">
												<label htmlFor="nome" className="form-label">Nome</label>
												<input type="text" onChange={handleChange} className="form-control" value={nome} name="nome" id="nome" />
											</div>
										</div>
										<div className="col-lg-4">
											<div className="mb-3">
												<label htmlFor="codice_fiscale" className="form-label">Codice Fiscale</label>
												<input type="text" onChange={handleChange} className="form-control" value={codice_fiscale} name="codice_fiscale" id="codice_fiscale" />
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

				<br />

				<div className="row">
					<div className="col-lg-12 text-center">
						<button type="button" onClick={handleCerca} className="btn btn-success">Cerca</button>
					</div>
				</div>

				<hr />

				<div className="row">
					<div className="offset-11 col-md-1">
						<Link to='/dipendenti/new' className="btn btn-info btn-block">Aggiungi</Link>
					</div>
				</div>
				<br />

				<TabellaDipendenti dipendenti={dipendenti} />

				
				<div className="row">
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<nav>
							<ul className="pagination pagination-rounded mb-0 justify-content-center">

								<li className="page-item">
									<button className="page-link" onClick={pagine.first ? () => paginate(pagine.first) : null} aria-label="Previous">
										<span aria-hidden="true">&laquo;</span>
									</button>
								</li>
								{
									pagine.before.map((num) => (
										<li key={num} className={"page-item " + ((active === num) ? 'active' : null)}><button className="page-link" onClick={() => paginate(num)}>{num}</button></li>
									))
								}
								<li className={"page-item " + ((active === dati.pagina) ? 'active' : null)}><button className="page-link">{dati.pagina}</button></li>
								{
									pagine.after.map((num) => (
										<li key={num} className={"page-item " + ((active === num) ? 'active' : null)}><button className="page-link" onClick={() => paginate(num)}>{num}</button></li>
									))
								}

								<li className="page-item">
									<button className="page-link" onClick={pagine.last ? () => paginate(pagine.last) : null} aria-label="Next">
										<span aria-hidden="true">&raquo;</span>
									</button>
								</li>
							</ul>
						</nav>
					</div>
					<div className="col-md-4"></div>
				</div>
				<br />
			</div>

		) : null
	);
};

export default ListaDipendenti;