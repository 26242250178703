import React from 'react';
import { useState } from 'react';

import './mod-dipendente.styles.css';

const ModDipendente = ({ collectChange, elencoDipendenti, elencoAutomezzi, errori: { id_dipendente } }) => {


	const [elencoCampi] = useState([1]);

	const { automezzi } = elencoAutomezzi;
	const { dipendenti } = elencoDipendenti;

	return (
		<div className="aggiungi-dipendente">
			{
				elencoCampi.map(campo => (
					<div className="row" key={campo}>
						<div className="col-lg-6">
							<div className="mb-3">
								<label htmlFor="id_automezzo" className="form-label">Automezzo</label>
								<select className={`form-select`} onChange={collectChange} name="id_automezzo">
									<option value="0">Seleziona</option>
									{
										automezzi.map((val) => (
											<option key={val.id} value={val.id}>{val.nome} &lt;{val.targa}&gt;</option>
										))
									}
								</select>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="mb-3">
								<label htmlFor="id_dipendente" className="form-label">Dipendente</label>
								<select required className={`form-select ${campo === 1 ? (id_dipendente ? 'is-invalid' : '') : ''}`} defaultValue={elencoDipendenti ? elencoDipendenti : ''} onChange={collectChange} name="id_dipendente">
									<option value="0">Seleziona</option>
									{
										dipendenti.map((val) => (
											<option key={val.id} value={val.id}>{val.nome_breve}</option>
										))
									}
								</select>
								<div className="invalid-feedback">
									Inserire almeno un dipendente per il turno
								</div>
							</div>
						</div>
					</div>
				))
			}
		</div>
	);
};

export default ModDipendente;