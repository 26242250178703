import React from 'react';
import { Link } from 'react-router-dom';

import './form-dipendenti.styles.scss';

const FormDipendenti = ({ errori, handleChange, nome, cognome, nome_breve, nascita, codice_fiscale, telefono, mail, id_mansione, id_contratto, data_assunzione, livello, id_tipologia, note, inviaDati }) => {
	return (
		<div className="form-dipendenti">
			<form action="" id="" method="post">
				<p className="text-primary font-14"><strong>Compila i campi</strong></p>
				<hr />

				<div className="row">
					<div className="col-lg-3">
						<div className="mb-3">
							<label htmlFor="assunto" className="form-label">Assunto il</label>
							<input className={`form-control ${errori.data_assunzione ? 'is-invalid' : null}`} id="data_assunzione" onChange={handleChange} type="date" name="data_assunzione" value={data_assunzione || ''} required />
							<div className="invalid-feedback">
								Inserire la data d'assunzione
							</div>
						</div>
					</div>
					<div className="col-lg-3">
						<label htmlFor="id_contratto" className="form-label">Area di Appartenenza</label>
						<select className="form-select" onChange={handleChange} id="id_contratto" name="id_contratto">
							<option value="0">Area Conduzione</option>
							<option value="1">Area Raccolta e Spazzamento</option>
							<option value="2">Area Tecnica Amministrativa</option>
							<option value="3">Area Officina e Servizi Generali</option>
						</select>
					</div>
					<div className="col-lg-3">
						<label htmlFor="mansione" className="form-label">Mansione</label>
						<select className="form-select" onChange={handleChange} id="id_mansione" name="id_mansione">
							<option value="0">Autista</option>
							<option value="1">Operatore</option>
							<option value="2">Operatore Spazzamento</option>
							<option value="3">Addetto Magazzino</option>
							<option value="4">Autista/Operatore</option>
							<option value="5">Operatore Isole Ecologiche</option>
							<option value="6">Manutentore</option>
							<option value="7">Operatore Ecologico</option>
							<option value="8">Capo Squadra</option>
							<option value="9">Responsabile</option>
							<option value="10">Addetto Area Officina</option>
							<option value="11">Addetto Area Capannone</option>
							<option value="12">Lavaggista</option>

						</select>
					</div>
					<div className="col-lg-3">
						<label htmlFor="livello" className="form-label">Livello</label>
						<select className="form-select" onChange={handleChange} id="livello" name="livello">
							<option value="0">Selezione</option>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="6">6</option>
							<option value="7">7</option>
							<option value="8">8</option>
						</select>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-3">
						<label htmlFor="id_tipologia" className="form-label">Tipologia</label>
						<select className="form-select" onChange={handleChange} id="id_tipologia" name="id_tipologia">
							<option value="0">Seleziona</option>
							<option value="1">Tipologia 1</option>
							<option value="2">Tipologia 2</option>
						</select>
					</div>
					<div className="col-lg-3">
						<label htmlFor="parametro" className="form-label">Parametro</label>
						<select className="form-select" onChange={handleChange} id="parametro" name="parametro">
							<option value="0">Seleziona</option>
							<option value="1">A</option>
							<option value="1">B</option>
						</select>
					</div>

					<div className="col-lg-3">
						<label htmlFor="patente" className="form-label">Patente</label>
						<select className="form-select" onChange={handleChange} id="patente" name="patente">
							<option value="0">Seleziona</option>
							<option value="1">A</option>
							<option value="2">B</option>
							<option value="3">C</option>
							<option value="4">D</option>
							<option value="5">E</option>
						</select>
					</div>
					<div className="col-lg-3">
						<label htmlFor="cqc" className="form-label">CQC</label>
						<select className="form-select" onChange={handleChange} id="cqc" name="cqc">
							<option value="0">SI</option>
							<option value="1">NO</option>
						</select>
					</div>
				</div>

				<br />
				<br />
				<p className="text-primary font-14"><strong>Inserisci Dati Anagrafici</strong></p>
				<hr />

				<div className="row">
					<div className="col-lg-3">
						<label htmlFor="cognome" className="form-label">Cognome</label>
						<input className={`form-control ${errori.cognome ? 'is-invalid' : null}`} id="cognome" onChange={handleChange} type="text" name="cognome" value={cognome || ''} required />
						<div className="invalid-feedback">
							Inserire il cognome
						</div>
					</div>
					<div className="col-lg-3">
						<div className="mb-3">
							<label htmlFor="nome" className="form-label">Nome</label>
							<input className={`form-control ${errori.nome ? 'is-invalid' : null}`} id="nome" onChange={handleChange} type="text" name="nome" value={nome || ''} required />
							<div className="invalid-feedback">
								Inserire il nome
							</div>
						</div>
					</div>
					<div className="col-lg-3">
						<div className="mb-3">
							<label htmlFor="nascita" className="form-label">Data di Nascita</label>
							<input className={`form-control ${errori.nascita ? 'is-invalid' : null}`} id="nascita" onChange={handleChange} type="date" name="nascita" value={nascita || ''} />
							<div className="invalid-feedback">
								Inserire la data di nascita
							</div>
						</div>
					</div>
					<div className="col-lg-3">
						<div className="mb-3">
							<label htmlFor="cf" className="form-label">Codice Fiscale</label>
							<input className={`form-control ${errori.codice_fiscale ? 'is-invalid' : null}`} id="codice_fiscale" onChange={handleChange} type="text" name="codice_fiscale" value={codice_fiscale || ''} required />
							<div className="invalid-feedback">
								Inserire il codice fiscale
							</div>
						</div>
					</div>
				</div>

				<br />
				<p className="text-primary font-14"><strong>Recapiti e Stato Dipendente</strong></p>
				<hr />

				<div className="row">
					<div className="col-lg-3">
						<label htmlFor="email" className="form-label">Email</label>
						<input className={`form-control ${errori.mail ? 'is-invalid' : null}`} id="mail" onChange={handleChange} type="text" name="mail" value={mail || ''} />
						<div className="invalid-feedback">
							Inserire l'email
						</div>
					</div>
					<div className="col-lg-3">
						<div className="mb-3">
							<label htmlFor="telefono" className="form-label">Telefono Personale</label>
							<input className={`form-control ${errori.telefono ? 'is-invalid' : null}`} id="telefono" onChange={handleChange} type="text" name="telefono" value={telefono || ''} />
							<div className="invalid-feedback">
								Inserire il telefono
							</div>
						</div>
					</div>

					<div className="col-lg-3">
						<div className="mb-3">
							<label htmlFor="telefono_aziendale" className="form-label">Telefono Aziendale</label>
							<input className="form-control" id="telefono_aziendale" onChange={handleChange} type="text" name="telefono_aziendale" />
							<div className="invalid-feedback">
								Inserire il telefono
							</div>
						</div>
					</div>
					<div className="col-lg-3">
						<div className="mb-3">
							<label htmlFor="attivo" className="form-label">Attivo</label>
							<select className="form-select" id="attivo" name="attivo">
								<option value="0">SI</option>
								<option value="1">NO</option>
							</select>
						</div>
					</div>
				</div>

				<br />
				<p className="text-primary font-14"><strong>Patenti</strong></p>
				<hr />

				<div className="row">
					<div className="col-lg-3">
						<label htmlFor="patente_ragno" className="form-label">Patentino Ragno</label>
						<select className="form-select" onChange={handleChange} id="patente_ragno" name="patente_ragno">
							<option value="0">SI</option>
							<option value="1">NO</option>
						</select>
					</div>

					<div className="col-lg-3">
						<label htmlFor="patente_trattore" className="form-label">Patentino Trattore</label>
						<select className="form-select" onChange={handleChange} id="patente_trattore" name="patente_trattore">
							<option value="0">SI</option>
							<option value="1">NO</option>
						</select>
					</div>

					<div className="col-lg-3">
						<label htmlFor="patente_muletto" className="form-label">Patentino Muletto</label>
						<select className="form-select" onChange={handleChange} id="patente_muletto" name="patente_muletto">
							<option value="0">SI</option>
							<option value="1">NO</option>
						</select>
					</div>

					<div className="col-lg-3">
						<label htmlFor="patente_palamecanica" className="form-label">Patentino Pala Meccanica</label>
						<select className="form-select" onChange={handleChange} id="patente_palamecanica" name="patente_palamecanica">
							<option value="0">SI</option>
							<option value="1">NO</option>
						</select>
					</div>
				</div>

				<br />
				<br />
				<p className="text-primary font-14"><strong>Annotazioni</strong></p>
				<hr />

				<div className="row">
					<div className="col-lg-4">
						<label htmlFor="legge_104" className="form-label">Legge 104</label>
						<select className="form-select" onChange={handleChange} id="legge_104" name="legge_104">
							<option value="0">SI</option>
							<option value="1">NO</option>
						</select>
					</div>

					<div className="col-lg-4">
						<label htmlFor="prescrizione" className="form-label">Prescrizione</label>
						<select className="form-select" onChange={handleChange} id="prescrizione" name="prescrizione">
							<option value="0">SI</option>
							<option value="1">NO</option>
						</select>
					</div>

					<div className="col-lg-4">
						<label htmlFor="limitazioni" className="form-label">Limitazioni</label>
						<select className="form-select" onChange={handleChange} id="limitazioni" name="limitazioni">
							<option value="0">SI</option>
							<option value="1">NO</option>
						</select>
					</div>
				</div>

				<br />

				<div className="row">
					<div className="col-lg-12">
						<label htmlFor="note" className="form-label">Note</label>
						<textarea className="form-select" onChange={handleChange} id="note" name="note"></textarea>
					</div>
				</div>

				<br />
				<br />

				<div className="row">
					<div className="col-lg-4"></div>
					<div className="col-lg-4 text-center">
						<Link to="/dipendenti" className="btn btn-danger">Torna Indietro</Link> &nbsp;&nbsp;
						<button type='button' onClick={inviaDati} className="btn btn-success">Salva Dati</button>
					</div>
					<div className="col-lg-4"></div>

				</div>
			</form >
		</div >
	);
};

export default FormDipendenti;