import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component';
import { selectError, selectLoading } from '../../../redux/features/features.selectors';
import { editAutomezzo } from '../../../redux/features/automezzi/automezzi.thunk';

import './edit-automezzo.styles.scss';
import FormAutomezzi from '../../../components/form-automezzi/form-automezzi.component';
import { toast } from 'react-toastify';
import { useGetAutomezzoQuery } from '../../../redux/features/features.query';

const EditAutomezzo = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const loading = useSelector(selectLoading);
	const error = useSelector(selectError);
	const { data, isFetching, isSuccess } = useGetAutomezzoQuery(id, { refetchOnMountOrArgChange: true });

	//console.log(isFetching);

	const [campi, setCampi] = useState({
		id: id,
		categoria_automezzo: '',
		targa: '',
		attivo: '',
		tipologia: '',
		mc: '',
		portata: '',
		disponibilita: '',
		scadenza_leasingnoleggio: '',
		albo_rifiuti: '',
		crono_tachigrafo: '',
		note: ''
	});

	const [errori, setErrori] = useState({
		categoria_automezzo: false,
		targa: false
	});


	const handleChange = (e) => {
		const { value, name } = e.target;
		setCampi({ ...campi, [name]: value });
		setErrori({ targa: false, categoria_automezzo: false });
	}

	const inviaDati = async (e) => {
		e.preventDefault();

		if (campi.categoria_automezzo === '') return setErrori({ ...errori, categoria_automezzo: true });
		if (campi.targa === '') return setErrori({ ...errori, targa: true });

		const result = await dispatch(editAutomezzo(campi));

		if (!result.error) {
			toast.success('Automezzo modificato con successo', {
				position: 'top-right'
			})
			navigate('/automezzi');
		} else {
			toast.error(result.payload, {
				position: 'top-right'
			})
		}
	}

	useEffect(() => {
		if (isSuccess) {
			setCampi(data.automezzo);
		}
	}, [data, isSuccess]);

	return (loading || isFetching) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		<div className="nuovo-automezzo">

			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">


							<p className="text-primary font-14"><strong>Modifica i Dati dell'Automezzo</strong></p>
							<hr />
							{
								(error) ?
									(<div className="alert alert-danger" role="alert">
										{error}
									</div>)
									: null
							}
							<FormAutomezzi errori={errori} handleChange={handleChange} {...campi} inviaDati={inviaDati} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditAutomezzo;