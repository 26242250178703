import React from 'react';

import './loader.styles.scss';

const Loader = () => {
	return (
		<div className="loader">
			<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
		</div>
	);
};

export default Loader;