import React from 'react';
import { Link } from 'react-router-dom';
import AggiungiDipendente from '../aggiungi-dipendente/aggiungi-dipendente.component';
import ScegliGiorno from '../scegli-giorno/scegli-giorno.component';
import ScegliTurnazione from '../scegli-turnazione/scegli-turnazione.component';

import './form-turni.styles.scss';

const FormTurni = ({ turnoData, error, errori, collectChange, oraInizio, oraFine, data, addDipendente, campi, selGiorni, note, handleInvia }) => {

	return (
		<div className="card-body">
			<hr />
			{
				(error) ?
					(<div className="alert alert-danger" role="alert">
						{error}
					</div>)
					: null
			}
			<form action="" id="" method="post">
				<p className="text-primary font-14"><strong>Scegli il giorno</strong></p>
				<hr />

				<ScegliGiorno giorno={turnoData?.giorno} errori={errori} collectChange={collectChange} />

				<br />
				<p className="text-primary font-14"><strong>Inserisci una nuova turnazione</strong></p>
				<hr />

				<ScegliTurnazione turnoData={turnoData} oraInizio={oraInizio} oraFine={oraFine} errori={errori} collectChange={collectChange} elencoServizi={data?.servizi} elencoAttivita={data?.attivita} />

				<div className="row">
					<div className="col-lg-12">
						<label htmlFor="note" className="form-label">Note</label>
						<textarea defaultValue={turnoData ? turnoData.note : ''} className="form-select" onChange={collectChange} id="note" name="note"></textarea>
					</div>
				</div>

				<br />

				<p className="text-primary font-14"><strong>Seleziona Automezzo e Dipendenti disponibili</strong></p>
				<hr />

				<AggiungiDipendente addDipendente={addDipendente} errori={errori} collectChange={collectChange} elencoDipendenti={data?.dipendenti} elencoAutomezzi={data?.automezzi} />
				{
					(campi.id === 0) ? (
						<>
							<br />
							<p className="text-primary font-14"><strong>Duplica giorno di turnazione <small className="text-danger">(opzionale)</small></strong></p>
							<hr />
							<div className="row">
								<div className="col-lg-3"></div>
								<div className="col-lg-3">
									<div className="mb-3">
										<label htmlFor="giornodal" className="form-label">Dal Giorno</label>
										<input className="form-control" onChange={collectChange} id="giornodal" type="date" name="giornodal" />
									</div>
								</div>
								<div className="col-lg-3">
									<div className="mb-3">
										<label htmlFor="giornoal" className="form-label">Al Giorno</label>
										<input className="form-control" onChange={collectChange} id="giornoal" type="date" name="giornoal" />
									</div>
								</div>
								<div className="col-lg-3"></div>
							</div>
							{
								(campi.giornodal !== '' && campi.giornoal !== '') ? (
									<div className="row">
										<div className="col-lg-12 text-center">
											<div className="mt-2">
												<div className="form-check form-check-inline">
													<input type="checkbox" className="form-check-input" onChange={selGiorni} name="lunedi" />
													<label className="form-check-label" htmlFor="lunedi">Lunedi</label>
												</div>
												<div className="form-check form-check-inline">
													<input type="checkbox" className="form-check-input" onChange={selGiorni} name="martedi" />
													<label className="form-check-label" htmlFor="martedi">Martedi</label>
												</div>
												<div className="form-check form-check-inline">
													<input type="checkbox" className="form-check-input" onChange={selGiorni} name="mercoledi" />
													<label className="form-check-label" htmlFor="mercoledi">Mercoledi</label>
												</div>
												<div className="form-check form-check-inline">
													<input type="checkbox" className="form-check-input" onChange={selGiorni} name="giovedi" />
													<label className="form-check-label" htmlFor="giovedi">Giovedi</label>
												</div>
												<div className="form-check form-check-inline">
													<input type="checkbox" className="form-check-input" onChange={selGiorni} name="venerdi" />
													<label className="form-check-label" htmlFor="venerdi">Venerdi</label>
												</div>
												<div className="form-check form-check-inline">
													<input type="checkbox" className="form-check-input" onChange={selGiorni} name="sabato" />
													<label className="form-check-label" htmlFor="sabato">Sabato</label>
												</div>
												<div className="form-check form-check-inline">
													<input type="checkbox" className="form-check-input" onChange={selGiorni} name="domenica" />
													<label className="form-check-label" htmlFor="domenica">Domenica</label>
												</div>
											</div>
										</div>
									</div>
								) : null
							}


							<br />
							<br />
						</>) : null
				}



				<div className="row">
					<div className="col-lg-4"></div>
					<div className="col-lg-4 text-center">
						<Link to="/turni" className="btn btn-danger">Torna Indietro</Link> &nbsp;&nbsp;
						<button onClick={handleInvia} className="btn btn-success">Salva Dati</button>
					</div>
					<div className="col-lg-4"></div>

				</div>
			</form>
		</div>
	);
};

export default FormTurni;
