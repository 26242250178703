import { createAsyncThunk } from "@reduxjs/toolkit";
import { ferie, ferieAdd, ferieDelete, ferieSearch,} from "./ferie.api";
import { logout } from "../../auth/auth.slice";

export const addFerie = createAsyncThunk(
    'features/addFerie',
    async (infoFerie, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await ferieAdd(stato.auth.user.token, infoFerie);
        if (response.esito === 1) {
            return response;
        }
        else if(response.esito === 0 && response.errorCode === 2)
        {
            return rejectWithValue('Ferie o Permesso gia esistente');
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

export const getFerie = createAsyncThunk(
    'features/ferie',
    async (pagina, { getState, rejectWithValue, dispatch }) => {

        const stato = getState();
        const response = await ferie(stato.auth.user.token, pagina);

        if (response.esito === 1) {
            return response;
        }
        else {
            if(response.code === 401)
            {
                dispatch(logout());
            }
            return rejectWithValue(response.error);
        }
    }
)

export const deleteFerie = createAsyncThunk(
    'features/deleteFerie',
    async (id, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await ferieDelete(stato.auth.user.token, id);
        if (response.esito === 1) {
            return response;
        }
        else if (response.esito === 2) {
            return rejectWithValue("Impossibile eliminare Ferie o Permesso");
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

export const searchFerie = createAsyncThunk(
    'features/searchFerie',
    async (info, { getState, rejectWithValue }) => {

        const stato = getState();
        const response = await ferieSearch(stato.auth.user.token, info.pagina, info.ricerca);

        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)
