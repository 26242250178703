import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteAttivita, getAttivita } from '../../redux/features/attivita/attivita.thunk';

import './tabella-attivita.styles.scss';

const TabellaAttivita = ({ attivita }) => {

	const dispatch = useDispatch();

	const del = async (id) => {

		if (window.confirm("Sei sicuro di voler eliminare quest'attività?")) {
			const result = await dispatch(deleteAttivita(id));

			if (result.error) {
				toast.error(result.payload);
			}
			else {
				toast.success("Attività eliminato con successo");
				dispatch(getAttivita(1));
			}
		}
	}

	return (

		<div className="tabella-attivita">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<table className="table table-striped mb-0">
								<thead className="table-dark">
									<tr>
										<th scope="col">Codice</th>
										<th scope="col">Attività</th>
										<th width="100" scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{
										(attivita && attivita.length > 0) ?
											attivita.map((attivita) => (
												<tr key={attivita.id}>
													<td>{attivita.codice}</td>
													<td>{attivita.attivita}</td>
													<td>
														<button type="button" onClick={() => del(attivita.id)} className="btn btn-danger" title='Elimina Attività'><i className="uil uil-times-square"></i></button> &nbsp;
													</td>
												</tr>
											))
											: (<tr>
												<td colSpan='2'>Nessun elemento trovato</td>
											</tr>)

									}

								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
};

export default TabellaAttivita;