import { baseAPI } from "../../../config";

export const dipendenti = async (token, pagina) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "dipendenti-" + pagina, {
        cache: 'no-cache',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const dipendentiAdd = async (token, infoDipendenti) => {
    return fetch(baseAPI + "dipendenti", {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
            cognome: infoDipendenti.cognome,
            nome: infoDipendenti.nome,
            nome_breve: infoDipendenti.nome_breve,
            nascita: infoDipendenti.nascita,
            codice_fiscale: infoDipendenti.codice_fiscale,
            telefono: infoDipendenti.telefono,
            mail: infoDipendenti.mail,
            id_mansione: infoDipendenti.id_mansione,
            id_contratto: infoDipendenti.id_contratto,
            data_assunzione: infoDipendenti.data_assunzione,
            livello: infoDipendenti.livello,
            id_tipologia: infoDipendenti.id_tipologia,
            note: infoDipendenti.note,
            attivo: 1
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const dipendentiEdit = async (token, infoDipendenti) => {
    return fetch(baseAPI + "dipendenti/" + infoDipendenti.id, {
        method: 'PATCH',
        cache: 'no-cache',
        body: JSON.stringify({
            cognome: infoDipendenti.cognome,
            nome: infoDipendenti.nome,
            nome_breve: infoDipendenti.nome_breve,
            nascita: infoDipendenti.nascita,
            codice_fiscale: infoDipendenti.codice_fiscale,
            telefono: infoDipendenti.telefono,
            mail: infoDipendenti.mail,
            id_mansione: infoDipendenti.id_mansione,
            id_contratto: infoDipendenti.id_contratto,
            data_assunzione: infoDipendenti.data_assunzione,
            livello: infoDipendenti.livello,
            id_tipologia: infoDipendenti.id_tipologia,
            note: infoDipendenti.note,
            attivo: 1
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const dipendentiDelete = async (token, id) => {
    return fetch(baseAPI + "dipendenti/" + id, {
        method: 'DELETE',
        cache: 'no-cache',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const dipendentiSearch = async (token, pagina, ricerca) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "dipendenti/search-" + pagina + '?' + new URLSearchParams(ricerca), {
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        
    }).then(response => response.json());
}