import { baseAPI } from "../../../config";

export const attivita = async (token, pagina) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "attivita-" + pagina, {
        cache: 'no-cache',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const attivitaAdd = async (token, infoAttivita) => {
    return fetch(baseAPI + "attivita", {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
            codice: infoAttivita.codice,
            attivita: infoAttivita.attivita,
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const attivitaDelete = async (token, id) => {
    return fetch(baseAPI + "attivita/" + id, {
        method: 'DELETE',
        cache: 'no-cache',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

