import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer/footer.component';


import './comunicazioni.styles.scss';

const Comunicazioni = () => {
	return (
		<div className="comunicazioni">
			<div className="content-page">
				<div className="content">


					<div className="container-fluid">


						<div className="row">
							<div className="col-md-6">
								<div className="page-title-box">
									<h4 className="page-title">Comunicazioni</h4>
								</div>
							</div>
							<div className="col-md-6">
								<div className="page-title-right float-end">
									<h4 className="page-title"><Link to="/nuova-comunicazione" className="btn btn-danger">Nuova comunicazione</Link></h4>
									
								</div>
							</div>
						</div>


						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-2">
												<div className="mb-3">
													<label htmlFor="destinatario" className="form-label">Destinatario</label>
													<select className="form-select" id="destinatario" name="destinatario">
														<option value="0">Seleziona</option>
														<option value="1">Destinatario 1</option>
														<option value="2">Destinatario 2</option>
														<option value="3">Destinatario 3</option>
													</select>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="mb-3">
													<label htmlFor="oggetto" className="form-label">Oggetto</label>
													<input className="form-control" id="oggetto" type="text" name="oggetto" />
												</div>
											</div>
											<div className="col-lg-2">
												<div className="mb-3">
													<label htmlFor="giornodal" className="form-label">Dal Giorno</label>
													<input className="form-control" id="giornodal" type="date" name="giornodal" />
												</div>
											</div>
											<div className="col-lg-2">
												<div className="mb-3">
													<label htmlFor="giornoal" className="form-label">Al Giorno</label>
													<input className="form-control" id="giornoal" type="date" name="giornoal" />
												</div>
											</div>
											<div className="col-lg-2">
												<div className="mb-3">
													<label className="form-label">&nbsp;</label>
													<Link href="http:" className="btn btn-success form-control">Cerca</Link>
												</div>
											</div>
										</div>
									</div>
								</div>

								<br />
								<br />

								<div className="card">
									<div className="card-body">

										<div className="mt-3">
											<ul className="email-list">
												<li className="unread">
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail1" />
																<label className="form-check-label" htmlFor="mail1"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline text-warning"></span>
														<Link to="/leggi-comunicazione" className="email-title">Lucas Kriebel (via Twitter)</Link>
													</div>
													<div className="email-content">
														<Link to="/leggi-comunicazione" className="email-subject">Lucas Kriebel (@LucasKriebel) has sent
															you a direct message on Twitter! &nbsp;&ndash;&nbsp;
															<span>@LucasKriebel - Very cool :) Nicklas, You have a new direct message.</span>
														</Link>
														<div className="email-date">11:49 am</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-open email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>

												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail2" />
																<label className="form-check-label" htmlFor="mail2"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline"></span>
														<Link href="http:" className="email-title">Randy, me (5)</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Last pic over my village &nbsp;&ndash;&nbsp;
															<span>Yeah i'd like that! Do you remember the video you showed me of your train ride between Colombo and Kandy? The one with the mountain view? I would love to see that one again!</span>
														</Link>
														<div className="email-date">5:01 am</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail3" />
																<label className="form-check-label" htmlFor="mail3"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline text-warning"></span>
														<Link href="http:" className="email-title">Andrew Zimmer</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Mochila Beta: Subscription Confirmed
															&nbsp;&ndash;&nbsp; <span>You've been confirmed! Welcome to the ruling class of the inbox. For your records, here is a copy of the information you submitted to us...</span>
														</Link>
														<div className="email-date">Mar 8</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li className="unread">
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail4" />
																<label className="form-check-label" htmlFor="mail4"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline"></span>
														<Link href="http:" className="email-title">Infinity HR</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Sveriges Hetaste sommarjobb &nbsp;&ndash;&nbsp;
															<span>Hej Nicklas Sandell! Vi vill bjuda in dig till "First tour 2014", ett rekryteringsevent som erbjuder jobb på 16 semesterorter iSverige.</span>
														</Link>
														<div className="email-date">Mar 8</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-open email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail5" />
																<label className="form-check-label" htmlFor="mail5"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline"></span>
														<Link href="http:" className="email-title">Web Support Dennis</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Re: New mail settings &nbsp;&ndash;&nbsp;
															<span>Will you answer him asap?</span>
														</Link>
														<div className="email-date">Mar 7</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail6" />
																<label className="form-check-label" htmlFor="mail6"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline text-warning"></span>
														<Link href="http:" className="email-title">me, Peter (2)</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Off on Thursday &nbsp;&ndash;&nbsp;
															<span>Eff that place, you might as well stay here with us instead! Sent from my iPhone 4 &gt; 4 mar 2014 at 5:55 pm</span>
														</Link>
														<div className="email-date">Mar 4</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail7" />
																<label className="form-check-label" htmlFor="mail7"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline"></span>
														<Link href="http:" className="email-title">Medium</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">This Week's Top Stories &nbsp;&ndash;&nbsp;
															<span>Our top pick for you on Medium this week The Man Who Destroyed America’s Ego</span>
														</Link>
														<div className="email-date">Feb 28</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail8" />
																<label className="form-check-label" htmlFor="mail8"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline text-warning"></span>
														<Link href="http:" className="email-title">Death to Stock</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Montly High-Res Photos &nbsp;&ndash;&nbsp;
															<span>To create this month's pack, we hosted a party with local musician Jared Mahone here in Columbus, Ohio.</span>
														</Link>
														<div className="email-date">Feb 28</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail9" />
																<label className="form-check-label" htmlFor="mail9"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline"></span>
														<Link href="http:" className="email-title">Revibe</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Weekend on Revibe &nbsp;&ndash;&nbsp;
															<span>Today's Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen!</span>
														</Link>
														<div className="email-date">Feb 27</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail10" />
																<label className="form-check-label" htmlFor="mail10"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline"></span>
														<Link href="http:" className="email-title">Erik, me (5)</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Regarding our meeting &nbsp;&ndash;&nbsp;
															<span>That's great, see you on Thursday!</span>
														</Link>
														<div className="email-date">Feb 24</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail11" />
																<label className="form-check-label" htmlFor="mail11"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline text-warning"></span>
														<Link href="http:" className="email-title">KanbanFlow</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Task assigned: Clone ARP's website
															&nbsp;&ndash;&nbsp; <span>You have been assigned a task by Alex@Work on the board Web.</span>
														</Link>
														<div className="email-date">Feb 24</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail12" />
																<label className="form-check-label" htmlFor="mail12"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline"></span>
														<Link href="http:" className="email-title">Tobias Berggren</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Let's go fishing! &nbsp;&ndash;&nbsp;
															<span>Hey, You wanna join me and Fred at the lake tomorrow? It'll be awesome.</span>
														</Link>
														<div className="email-date">Feb 23</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail13" />
																<label className="form-check-label" htmlFor="mail13"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline text-warning"></span>
														<Link href="http:" className="email-title">Charukaw, me (7)</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Hey man &nbsp;&ndash;&nbsp; <span
														>Nah man sorry i don't. Should i get it?</span>
														</Link>
														<div className="email-date">Feb 23</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li className="unread">
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail14" />
																<label className="form-check-label" htmlFor="mail14"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline text-warning"></span>
														<Link href="http:" className="email-title">me, Peter (5)</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Home again! &nbsp;&ndash;&nbsp; <span
														>That's just perfect! See you tomorrow.</span>
														</Link>
														<div className="email-date">Feb 21</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-open email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail15" />
																<label className="form-check-label" htmlFor="mail15"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline"></span>
														<Link href="http:" className="email-title">Stack Exchange</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">1 new items in your Stackexchange inbox
															&nbsp;&ndash;&nbsp; <span>The following items were added to your Stack Exchange global inbox since you last checked it.</span>
														</Link>
														<div className="email-date">Feb 21</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail16" />
																<label className="form-check-label" htmlFor="mail16"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline text-warning"></span>
														<Link href="http:" className="email-title">Google Drive Team</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">You can now use your storage in Google
															Drive &nbsp;&ndash;&nbsp; <span>Hey Nicklas Sandell! Thank you for purchasing extra storage space in Google Drive.</span>
														</Link>
														<div className="email-date">Feb 20</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li className="unread">
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail17" />
																<label className="form-check-label" htmlFor="mail17"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline"></span>
														<Link href="http:" className="email-title">me, Susanna (11)</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Train/Bus &nbsp;&ndash;&nbsp; <span
														>Yes ok, great! I'm not stuck in Stockholm anymore, we're making progress.</span>
														</Link>
														<div className="email-date">Feb 19</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-open email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail18" />
																<label className="form-check-label" htmlFor="mail18"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline"></span>
														<Link href="http:" className="email-title">Peter, me (3)</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Hello &nbsp;&ndash;&nbsp; <span
														>Trip home from Colombo has been arranged, then Jenna will come get me from Stockholm. :)</span>
														</Link>
														<div className="email-date">Jan 28</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail19" />
																<label className="form-check-label" htmlFor="mail19"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline"></span>
														<Link href="http:" className="email-title">me, Susanna (7)</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Since you asked... and i'm
															inconceivably bored at the train station &nbsp;&ndash;&nbsp;
															<span>Alright thanks. I'll have to re-book that somehow, i'll get back to you.</span>
														</Link>
														<div className="email-date">Jan 25</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>

												<li>
													<div className="email-sender-info">
														<div className="checkbox-wrapper-mail">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="mail20" />
																<label className="form-check-label" htmlFor="mail20"></label>
															</div>
														</div>
														<span className="star-toggle mdi mdi-star-outline"></span>
														<Link href="http:" className="email-title">Randy, me (5)</Link>
													</div>
													<div className="email-content">
														<Link href="http:" className="email-subject">Last pic over my village &nbsp;&ndash;&nbsp;
															<span>Yeah i'd like that! Do you remember the video you showed me of your train ride between Colombo and Kandy? The one with the mountain view? I would love to see that one again!</span>
														</Link>
														<div className="email-date">Jan 22</div>
													</div>
													<div className="email-action-icons">
														<ul className="list-inline">
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-archive email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-delete email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-email-mark-as-unread email-action-icons-item"></i></Link>
															</li>
															<li className="list-inline-item">
																<Link href="http:"><i className="mdi mdi-clock email-action-icons-item"></i></Link>
															</li>
														</ul>
													</div>
												</li>
											</ul>
										</div>


										<div className="row">
											<div className="col-7 mt-1">
												Mostra 1 - 20 of 289
											</div>
											<div className="col-5">
												<div className="btn-group float-end">
													<button type="button" className="btn btn-light btn-sm"><i className="mdi mdi-chevron-left"></i></button>
													<button type="button" className="btn btn-info btn-sm"><i className="mdi mdi-chevron-right"></i></button>
												</div>
											</div>
										</div>



									</div>

									<div className="clearfix"></div>
								</div>

							</div>
						</div>

					</div>

				</div>
				<div id="compose-modal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="compose-header-modalLabel" aria-hidden="true">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header modal-colored-header bg-primary">
								<h4 className="modal-title" id="compose-header-modalLabel">Nuova comunicazione</h4>
								<button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div className="p-1">
								<div className="modal-body px-3 pt-3 pb-0">
									<form>
										<div className="mb-2">
											<label htmlFor="destinatario" className="form-label">Destinatario</label>
											<select className="form-select" id="destinatario" name="destinatario">
												<option value="0">Seleziona</option>
												<option value="1">Destinatario 1</option>
												<option value="2">Destinatario 2</option>
												<option value="3">Destinatario 3</option>
											</select>
										</div>
										<div className="mb-2">
											<label htmlFor="oggetto" className="form-label">Oggetto</label>
											<input type="text" id="oggetto" className="form-control" placeholder="oggetto" />
										</div>
										<div className="write-mdg-box mb-3">
											<label className="form-label">Messaggio</label>
											<textarea id="simplemde1"></textarea>
										</div>
									</form>
								</div>
								<div className="px-3 pb-3">
									<button type="button" className="btn btn-primary" data-bs-dismiss="modal"><i className="mdi mdi-send me-1"></i> Invia</button>
									<button type="button" className="btn btn-light" data-bs-dismiss="modal">Chiudi</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
};

export default Comunicazioni;