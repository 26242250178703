import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormFerie from '../../../components/form-ferie/form-ferie.component';
import { selectLoading } from '../../../redux/features/features.selectors';
import { addFerie } from '../../../redux/features/ferie/ferie.thunk';

import './aggiungi-ferie.styles.scss';
import { toast } from 'react-toastify';
import Loader from '../../../components/loader/loader.component';

const AggiungiFerie = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loading = useSelector(selectLoading);

	const [campi, setCampi] = useState({

		id_dipendente: '',
		id_tipo: '',
		dal: '',
		al: ''
	});

	const [errori, setErrori] = useState({
		id_dipendente: false,
		id_tipo: false
	});


	const handleChange = (e) => {
		const { value, name } = e.target;
		setCampi({ ...campi, [name]: value });
		setErrori({ id_dipendente: false, id_tipo: false });
	}

	const inviaDati = async (e) => {
		e.preventDefault();

		if (campi.id_dipendente === '') return setErrori({ ...errori, id_dipendente: true });
		if (campi.id_tipo === '') return setErrori({ ...errori, id_tipo: true });

		const result = await dispatch(addFerie(campi));

		if (!result.error) {
			toast.success('Permesso o Ferie inserito con successo', {
				position: 'top-right'
			})
			navigate('/ferie');
		} else {
			toast.error(result.payload, {
				position: 'top-right'
			})
		}
	}

	return (loading) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (

		<div className="aggiungi-ferie">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<FormFerie errori={errori} handleChange={handleChange} {...campi} inviaDati={inviaDati} />
						</div>
					</div>
				</div>
			</div>
		</div>

	);
};

export default AggiungiFerie;