import React from 'react';

import './form-attivita.styles.scss';
import { Link } from 'react-router-dom';

const FormAttivita = ({ errori, handleChange, codice, attivita, inviaDati }) => {
    return (
        <div className='form-attiva'>
            <form action="" id="" method="post">
                <p className="text-primary font-14"><strong>Aggiungi una nuova Attivita'</strong></p>
                <hr />
                <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-3">
                        <div className="mb-4">
                            <label htmlFor="codice" className="form-label">Codice</label>
                            <input className={`form-control ${errori.nome ? 'is-invalid' : null}`} id="codice" onChange={handleChange} type="text" name="codice" value={codice || ''} />
                            <div className="invalid-feedback">
                                Inserire un codice per l'attività
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mb-4">
                            <label htmlFor="attivita" className="form-label">Attivita'</label>
                            <input className={`form-control ${errori.nome ? 'is-invalid' : null}`} id="attivita" onChange={handleChange} type="text" name="attivita" value={attivita || ''} required />
                            <div className="invalid-feedback">
                                Inserire un nome per l'attività
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-3"></div>
                </div>

                <br />
                <br />

                <div className="row">
					<div className="col-lg-4"></div>
					<div className="col-lg-4 text-center">
						<Link to="/attivita" className="btn btn-danger">Torna Indietro</Link> &nbsp;&nbsp;
						<button type="button" onClick={inviaDati} className="btn btn-success">Salva Dati</button>
					</div>
					<div className="col-lg-4"></div>

				</div>
            </form>
        </div>

    );
};

export default FormAttivita;