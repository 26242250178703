import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseAPI } from '../../config'

export const utilityApi = createApi({
    reducerPath: 'utilityApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseAPI,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.user.token;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getAutomezzo: builder.query({
            query: (id) => `automezzi/${id}`
        }),

        getFerie: builder.query({
            query: (id) => `ferie/${id}`
        }),

        getDipendente: builder.query({
            query: (id) => `dipendenti/${id}`
        }),

        getConsuntivo: builder.query({
            query: (id) => `consuntivo/${id}`,
            providesTags: ['Consuntivo']
        }),

        getInformations: builder.query({
            query: () => `turni/informations`
        }),

        getDefaultOrari: builder.query({
            query: ({idServizio, idAttivita}) => `default-orari/${idServizio}-${idAttivita}`
        }),

        getSingleTurno: builder.query({
            query: (id) => `turni/edit/${id}`
        }),
        getExcel: builder.query({
            query: () => `turniexcel`,
            forceRefetch: true
        }),
        getExcelFerie: builder.query({
            query: () => `feriexcel`,
            forceRefetch: true
        }),
        getExcelConsuntivo: builder.query({
            query: () => `consuntivoexcel`,
            forceRefetch: true
        }),
        addConsuntivo: builder.mutation({
            query: (body) => ({
                url: `consuntivo`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Consuntivo']
        })
    })

})

export const { useGetAutomezzoQuery, useGetFerieQuery, useGetDipendenteQuery, useGetConsuntivoQuery, useGetInformationsQuery, useGetDefaultOrariQuery, useGetSingleTurnoQuery, useLazyGetExcelQuery, useLazyGetExcelFerieQuery, useLazyGetExcelConsuntivoQuery, useAddConsuntivoMutation } = utilityApi;
