import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Wrapper from './pages/wrapper/wrapper.component';
import Login from './pages/login/login.component'
import { useSelector } from 'react-redux';
import { selectUser } from './redux/auth/auth.selectors';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

function App() {

  const user = useSelector(selectUser);

  return (
    <div className="App">
      <Routes>
        <Route path='/login' element={user === null ? (<Login />) : (<Navigate to='/' />)} />
        <Route path='/*' element={user !== null ? (<Wrapper />) : (<Navigate to='/login' />)} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
