import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './form-consuntivo.styles.css';
import AggiungiOreConsuntivo from '../aggiungi-ore-consuntivo/aggiungi-ore-consuntivo.component';
import { useAddConsuntivoMutation, useGetConsuntivoQuery } from '../../redux/features/features.query';
import { toast } from 'react-toastify';

const FormConsuntivo = () => {

    const navigator = useNavigate();
    const { data } = useGetConsuntivoQuery({ refetchOnMountOrArgChange: true });
	const [salvaConsuntivo] = useAddConsuntivoMutation();

    const [campi, setCampi] = useState({
        dipendente: '',
        data_presenza: '',
        presenza: '',
        ordinario: '',
        straordinario: '',
        inizio: '',
        fine: '',
        notturno: '',
        malattia: '',
        infortunio: '',
        note: '',
        ndipendenti: 1
    });

    const [errori, setErrori] = useState({
        notturno: false,
    });

    const collectChange = (e) => {
        const { value, name } = e.target;
		//console.log(`cambiato ${name} con ${value}`)
        setCampi({ ...campi, [name]: value });
        setErrori({ notturno: false });
    }

    const addConsuntivo = () => {
        setCampi({ ...campi, ndipendenti: Number(campi.ndipendenti + 1) })
    }

    const handleInvia = async (e) => {
        e.preventDefault();
       // console.log("TEST", campi);
        if (campi.notturno === '') return setErrori({ ...errori, notturno: true });

		//console.log("SALVA")

		salvaConsuntivo({
			...campi
		}).unwrap().then(res => {
			toast.success('Consuntivo inserito con successo', {
                position: 'top-right'
            })
            navigator('/consuntivo');
		}).catch(err => {
			toast.error('errore', {
                position: 'top-right'
            })
		})
    }




    return (
		<div className="form-dipendenti">
			<p className="text-primary font-14"><strong>Assegna ore al dipendente</strong></p>
			<hr />
			<form action="" id="" method="post">
				<div className="row">
					<div className="col-lg-4"></div>
					<div className="col-lg-4">
						<div className="mb-3">
							<label htmlFor="data_presenza" className="form-label">Giorno Presenza</label>
							<input className="form-control" onChange={collectChange} id="data_presenza" type="date" name="data_presenza" />
							<div className="invalid-feedback">
								inserire la data
							</div>
						</div>
					</div>
					<div className="col-lg-4"></div>
				</div>
				<div className="row">
					<div className="col-lg-6">
						<div className="mb-3">
							<label htmlFor="notturno" className="form-label">Ore Notturno</label>
							<input className="form-control" onChange={collectChange} id="notturno" type="text" name="notturno" />
							<div className="invalid-feedback">
								inserire le ore
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="mb-3">
							<label htmlFor="ordinario" className="form-label">Ore Ordinario</label>
							<input className="form-control" onChange={collectChange} id="ordinario" type="text" name="ordinario" />
							<div className="invalid-feedback">
								inserire le ore
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4">
						<div className="mb-3">
							<label htmlFor="straordinario" className="form-label">Ore Straordinario</label>
							<input className="form-control" onChange={collectChange} id="straordinario" type="text" name="straordinario" />
							<div className="invalid-feedback">
								inserire le ore
							</div>
						</div>
					</div>

					<div className="col-lg-4">
						<div className="mb-3">
							<label htmlFor="inizio" className="form-label">Ora Inizio Strordinario</label>
							<input className="form-control" onChange={collectChange} id="inizio" type="text" name="inizio" />
							<div className="invalid-feedback">
								inserire le ore
							</div>
						</div>
					</div>

					<div className="col-lg-4">
						<div className="mb-3">
							<label htmlFor="fine" className="form-label">Ora Fine Strordinario</label>
							<input className="form-control" onChange={collectChange} id="fine" type="text" name="fine" />
							<div className="invalid-feedback">
								inserire le ore
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6">
						<div className="mb-3">
							<label htmlFor="malattia" className="form-label">Giorni Malattia</label>
							<input className="form-control" onChange={collectChange} id="malattia" type="text" name="malattia" />
							<div className="invalid-feedback">
								inserire le ore
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="mb-3">
							<label htmlFor="infortunio" className="form-label">Giorni Infortunio</label>
							<input className="form-control" onChange={collectChange} id="infortunio" type="text" name="infortunio" />
							<div className="invalid-feedback">
								inserire le ore
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="mb-3">
							<label htmlFor="note" className="form-label">Note</label>
							<input className="form-control" onChange={collectChange} id="note" type="text" name="note" />
							<div className="invalid-feedback">
								inserire la data
							</div>
						</div>
					</div>
				</div>

				<hr />

				<AggiungiOreConsuntivo addConsuntivo={addConsuntivo} data={data} collectChange={collectChange} />

				<hr />
				<br />
				<br />

				<div className="row">
					<div className="col-lg-4"></div>
					<div className="col-lg-4 text-center">
						<Link to="/consuntivo" className="btn btn-danger">Torna Indietro</Link> &nbsp;&nbsp;
						<button type='button' onClick={handleInvia} className="btn btn-success">Salva Dati</button>
					</div>
					<div className="col-lg-4"></div> 

				</div>
			</form>
		</div>

	);
};

export default FormConsuntivo;