import React from 'react';
import Footer from '../../components/footer/footer.component';
import { Link } from 'react-router-dom';

import './nuova-comunicazione.styles.scss';

const NuovaComunicazione = () => {
    return (
        <div className="nuova-comunicazione">
            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box">
                                    <h4 className="page-title">Comunicazioni</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <form action="" id="" method="post">
                                <div className="mb-2">
                                    <label htmlFor="destinatario" className="form-label">Destinatario</label>
                                    <select className="form-select" id="destinatario" name="destinatario">
                                        <option value="0">Seleziona</option>
                                        <option value="1">Destinatario 1</option>
                                        <option value="2">Destinatario 2</option>
                                        <option value="3">Destinatario 3</option>
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="oggetto" className="form-label">Oggetto</label>
                                    <input type="text" id="oggetto" className="form-control" placeholder="oggetto" />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="allegati" className="form-label">Allegati</label>
                                    <input type="file" id="allegati" name="allegati" className="form-control" />
                                </div>
                                <div className="write-mdg-box mb-3">
                                    <label className="form-label">Messaggio</label>
                                    <textarea className="form-control" id="messaggio" name="messaggio"></textarea>
                                </div>
                            </form>
                        </div>
                        <br />
                        <br />

                        <div className="row">
                            <div className="col-lg-4"></div>
                            <div className="col-lg-4 text-center">
                                <Link to="/comunicazioni" className="btn btn-danger">Torna Indietro</Link> &nbsp;&nbsp;
                                <Link to="http:" className="btn btn-success">Salva Dati</Link>
                            </div>
                            <div className="col-lg-4"></div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>

    );
};

export default NuovaComunicazione;