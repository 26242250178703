import React from 'react';
import { useState } from 'react';

import { useGetDefaultOrariQuery } from '../../redux/features/features.query';
import Loader from '../loader/loader.component';

import './scegli-turnazione.styles.scss';

const ScegliTurnazione = ({ turnoData, oraFine, oraInizio, collectChange, elencoServizi, elencoAttivita, errori }) => {

	const [serviziAttivita, setServiziAttivita] = useState({
		idServizio: 0,
		idAttivita: 0
	})

	const { servizi } = elencoServizi;
	const { attivita } = elencoAttivita;

	const { idServizio, idAttivita } = serviziAttivita;

	const { data, isFetching } = useGetDefaultOrariQuery({ idServizio, idAttivita }, { refetchOnMountOrArgChange: true });

	const handleChange = (e) => {

		const { name, value } = e.target;

		const valueNumber = Number(value);

		if (name === 'id_servizio') {
			setServiziAttivita({ ...serviziAttivita, idServizio: valueNumber });
		}

		if (name === 'id_attivita') {
			setServiziAttivita({ ...serviziAttivita, idAttivita: valueNumber });
		}

		collectChange(e);

	}

	let defaultValueServizio = 0;
	let defaultValueAttivita = 0;
	let defaultValueInizio = '';
	let defaultValueFine = '';

	if (turnoData && !serviziAttivita.idServizio) {
		defaultValueServizio = turnoData.id_servizio
		serviziAttivita.idServizio = defaultValueServizio
	}
	else { defaultValueServizio = serviziAttivita.idServizio }

	if (turnoData && !serviziAttivita.idAttivita) {
		defaultValueAttivita = turnoData.id_attivita
		serviziAttivita.idAttivita = defaultValueAttivita
	}
	else { defaultValueAttivita = serviziAttivita.idAttivita }


	if (turnoData) {
		if(!data)
		{
			defaultValueInizio = turnoData.oraInizio;
			defaultValueFine = turnoData.oraFine;
		}
		else
		{
			defaultValueInizio = data.orario_inizio
			defaultValueFine = data.orario_fine
		}
	}
	else {
		if (data) {
			defaultValueInizio = data.orario_inizio
			defaultValueFine = data.orario_fine
		}
	}

	return (isFetching && idServizio !== 0 && idAttivita !== 0) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		<div className="scegli-turnazione">
			<div className="row">
				<div className="col-lg-3">
					<div className="mb-3">
						<label htmlFor="id_servizio" className="form-label">Tipologia Servizio</label>
						<select required className={`form-select ${errori.id_servizio ? 'is-invalid' : null}`} defaultValue={defaultValueServizio} onChange={handleChange} id="id_servizio" name="id_servizio">
							<option value="0">Seleziona</option>
							{
								servizi.map((val) => (
									<option key={val.id} value={val.id}>{val.servizio}</option>
								))
							}
						</select>
						<div className="invalid-feedback">
							Indicare il servizio da svolgere
						</div>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="mb-3">
						<label htmlFor="id_attivita" className="form-label">Tipologia Attivita'</label>
						<select required className={`form-select ${errori.id_attivita ? 'is-invalid' : null}`} defaultValue={defaultValueAttivita} onChange={handleChange} id="id_attivita" name="id_attivita">
							<option value="0">Seleziona</option>
							{
								attivita.map((val) => (
									<option key={val.id} value={val.id}>{val.codice} - {val.attivita}</option>
								))
							}
						</select>
						<div className="invalid-feedback">
							Indicare l'attività da svolgere
						</div>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="mb-3">
						<label htmlFor="ora_inizio" className="form-label">Orario Inizio</label>
						<input ref={oraInizio} required className={`form-control ${errori.ora_inizio ? 'is-invalid' : null}`} id="ora_inizio" type="time" name="ora_inizio" onChange={collectChange} defaultValue={defaultValueInizio} />
						<div className="invalid-feedback">
							Inserire un orario di inizio attività
						</div>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="mb-3">
						<label htmlFor="ora_fine" className="form-label">Orario Fine</label>
						<input ref={oraFine} required className={`form-control ${errori.ora_fine ? 'is-invalid' : null}`} id="ora_fine" type="time" name="ora_fine" onChange={collectChange} defaultValue={defaultValueFine} />
						<div className="invalid-feedback">
							Inserire un orario di fine attività
						</div>
					</div>
				</div>
			</div>

			<br />

		</div>
	);
};

export default ScegliTurnazione;