import React, { useState } from 'react';

import './aggiungi-ore-consuntivo.styles.css';

const AggiungiOreConsuntivo = ({ collectChange, addConsuntivo }) => {

	const [elencoCampi, setElencoCampi] = useState([1]);

	const addCampo = () => {
		setElencoCampi(campo => [...campo, Number(campo.pop() + 1)]);
		addConsuntivo();
	}

	return (
		<div className="aggiungi-ore-consuntivo">
			{
				elencoCampi.map(campo => (

					<div className="row" key={campo}>
						<div className="col-lg-12 mb-3">
							<label htmlFor="dipendente" className="form-label">Dipendente</label>
							<select required className="form-select" onChange={collectChange} name={`dipendente_${campo}`} id="dipendente">
							<option value="0">Seleziona</option>
								<option value="Amoruso E.">Amoruso E.</option>
								<option value="Amoruso M.">Amoruso M.</option>
								<option value="Cirisano">Cirisano</option>
								<option value="Gerace S.">Gerace S.</option>
								<option value="Ienopoli">Ienopoli</option>
								<option value="Mungari G.">Mungari G.</option>
								<option value="Campagna">Campagna</option>
								<option value="Corigliano F.">Corigliano F.</option>
								<option value="Manfredi">Manfredi</option>
								<option value="Sulla">Sulla</option>
								<option value="Torchia">Torchia</option>
								<option value="Valenti">Valenti</option>
								<option value="Elia">Elia</option>
								<option value="Gallina">Gallina</option>
								<option value="Caruso">Caruso</option>
								<option value="Cetera">Cetera</option>
								<option value="Grande">Grande</option>
								<option value="Pignolo">Pignolo</option>
								<option value="Del Poggetto">Del Poggetto</option>
								<option value="Perri">Perri</option>
								<option value="Ruggiero">Ruggiero</option>
								<option value="Asteriti">Asteriti</option>
								<option value="Catanzaro P.">Catanzaro P.</option>
								<option value="Tisci">Tisci</option>
								<option value="Infante">Infante</option>
								<option value="Vrenna">Vrenna</option>
								<option value="Russo">Russo</option>
								<option value="Taschera">Taschera</option>
								<option value="Alessi">Alessi</option>
								<option value="Cammariere">Cammariere</option>
								<option value="Catanzaro G.">Catanzaro G.</option>
								<option value="Gaetani">Gaetani</option>
								<option value="Lumare A.">Lumare A.</option>
								<option value="Riga F.">Riga F.</option>
								<option value="Vona V.">Vona V.</option>
								<option value="Corigliano G.">Corigliano G.</option>
								<option value="De Fazio">De Fazio</option>
								<option value="Loria">Loria</option>
								<option value="Pugliese">Pugliese</option>
								<option value="Rizzo">Rizzo</option>
								<option value="Sarcone">Sarcone</option>
								<option value="Arcuri">Arcuri</option>
								<option value="Catanzaro F.">Catanzaro F.</option>
								<option value="Correale">Correale</option>
								<option value="De Sole">De Sole</option>
								<option value="Infusino">Infusino</option>
								<option value="Lumare M.">Lumare M.</option>
								<option value="Muscatello">Muscatello</option>
								<option value="Raso">Raso</option>
								<option value="Riga D.">Riga D.</option>
								<option value="Riolo">Riolo</option>
								<option value="Valente">Valente</option>
								<option value="Oliverio">Oliverio</option>
								<option value="Proietto L.">Proietto L.</option>
								<option value="Lomoro">Lomoro</option>
								<option value="Manica">Manica</option>
								<option value="Noce">Noce</option>
								<option value="Passalacqua">Passalacqua</option>
								<option value="Corigliano A.">Corigliano A.</option>
								<option value="Esposito">Esposito</option>
								<option value="Galea">Galea</option>
								<option value="Graziano V.">Graziano V.</option>
								<option value="Leo">Leo</option>
								<option value="Paglia64">Paglia</option>
								<option value="Pasqua">Pasqua</option>
								<option value="Scarriglia">Scarriglia</option>
								<option value="Spano'">Spano'</option>
								<option value="Attisano">Attisano</option>
								<option value="Catanzaro D.">Catanzaro D.</option>
								<option value="Cava">Cava</option>
								<option value="Chimirri">Chimirri</option>
								<option value="Clerico">Clerico'</option>
								<option value="Colurcio">Colurcio</option>
								<option value="Crugliano">Crugliano</option>
								<option value="Federico">Federico</option>
								<option value="Gallo">Gallo</option>
								<option value="Gerace G.">Gerace G.</option>
								<option value="Gerace">Gerace</option>
								<option value="Madia">Madia</option>
								<option value="Mazza">Mazza</option>
								<option value="Milone">Milone</option>
								<option value="Mungari A.">Mungari A.</option>
								<option value="Panucci N.">Panucci N.</option>
								<option value="Proietto S.">Proietto S.</option>
								<option value="Ristagno">Ristagno</option>
								<option value="Rocca">Rocca</option>
								<option value="Rota">Rota</option>
								<option value="Varano">Varano</option>
								<option value="Vona G.">Vona G.</option>
								<option value="Graziano C.">Graziano C.</option>
								<option value="Nicoletta">Nicoletta</option>
								<option value="Bonaccio">Bonaccio</option>
								<option value="Gerace Salvatore">Gerace Salvatore</option>
								<option value="Regalino">Regalino</option>
								<option value="Marino">Marino</option>
								<option value="Maiolo">Maiolo</option>
							</select>
						</div>
						
					</div>

				))
			}
			<div className="col-lg-12 text-center">
				<div className="mb-3">
					<label className="form-label">&nbsp;</label>
					<button type="button" onClick={addCampo} className="btn btn-success mt-30"><i className="uil uil-plus"></i> Aggiungi Dipendente</button>
				</div>
			</div>
		</div>
	);
};

export default AggiungiOreConsuntivo;