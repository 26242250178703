import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../components/loader/loader.component';
import { selectLoading, selectTurni, selectUser } from '../../redux/features/features.selectors';
import { capitalizeFirstLetter } from '../../utilities/utility';

import './personal.styles.scss';

const Personal = () => {
    const user = useSelector(selectUser); // Ottieni le informazioni dell'utente autenticato
    const turni = useSelector(selectTurni); // Ottieni tutti i turni
    const loading = useSelector(selectLoading); // Stato di caricamento

    const [giorno, setGiorno] = useState(new Date());
    const [tipoTurno, setTipoTurno] = useState(0);

    const changeDay = (day) => {
        if (day > 0) {
            setGiorno(moment(giorno).add(1, 'day').toDate());
        } else {
            setGiorno(moment(giorno).subtract(1, 'day').toDate());
        }
    };

    const handleType = (tipo) => {
        if (tipoTurno === tipo) {
            setTipoTurno(0);
        } else {
            setTipoTurno(tipo);
        }
    };

    // Filtra i turni in base all'utente e alla condizione di visualizzazione
    const filteredTurni = turni.filter(turno => {
        if (user.id >= 1 && user.id <= 8) {
            return true; // Visualizza tutti i turni per gli utenti da 1 a 8
        } else {
            return turno.userID === user.id; // Visualizza solo i turni dell'utente corrente per gli utenti con id da 8 in poi
        }
    });

    useEffect(() => {
        // La logica per caricare i turni è rimasta invariata
        // Qui potresti aggiungere la logica per caricare i turni dell'utente corrente in base alle sue preferenze
    }, [giorno, tipoTurno]);

    return loading ? (
        <div className='attendere'>
            <Loader />
        </div>
    ) : (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-8">
                            <div className="page-title-box">
                                <h4 className="page-title">Calendario Attivita'</h4>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="page-title-box text-end">
                                <h4 className="page-title">
                                    {(tipoTurno === 0) ? (<strong className="text-primary">TURNI DEL GIORNO</strong>) : null}
                                    {(tipoTurno === 1) ? (<strong className="text-primary">TURNI RACCOLTA DIFFERENZIATA</strong>) : null}
                                    {(tipoTurno === 2) ? (<strong className="text-primary">PRIMO TURNO</strong>) : null}
                                    {(tipoTurno === 3) ? (<strong className="text-primary">TURNO POMERIDIANO</strong>) : null}
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-2">
                            <div className='pointer' onClick={() => changeDay(-1)}>
                                <div className="card border-danger border">
                                    <div style={{ paddingBottom: "30px" }} className="card-body text-center">
                                        <h5 className="card-title text-danger"><br />Giorno Precedente</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card text-white bg-warning">
                                <div className="card-body text-center">
                                    <h2>{(giorno != null) ? capitalizeFirstLetter(giorno.toLocaleDateString('it-IT', { weekday: 'long' })) + ', ' + giorno.toLocaleDateString('it-IT', { year: 'numeric', month: 'long', day: 'numeric' }) : null}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className='pointer' onClick={() => changeDay(1)}>
                                <div className="card border-success border">
                                    <div style={{ paddingBottom: "30px" }} className="card-body text-center">
                                        <h5 className="card-title text-success"><br />Giorno Successivo </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <button type='button' onClick={() => handleType(1)} className={`btn btn-${tipoTurno === 1 ? 'success' : 'light'}`}>Raccolta differenziata</button> &nbsp;
                            <button type='button' onClick={() => handleType(2)} className={`btn btn-${tipoTurno === 2 ? 'success' : 'light'}`}>Primo turno</button> &nbsp;
                            <button type='button' onClick={() => handleType(3)} className={`btn btn-${tipoTurno === 3 ? 'success' : 'light'}`}>Turno pomeridiano</button>
                        </div>
                    </div>

                    <hr />
                    <br />

                    {/* Visualizza i turni filtrati */}
                    {filteredTurni.map((turno, index) => (
                        <div key={index} className="card border-primary border">
                            <div className="card-body">
                                <h5 className="card-title text-primary">Servizio: <span className="text-danger">{turno.servizio}</span></h5>
                                <h5 className="card-title text-primary">Attivita': <span className="text-danger">{turno.attivita}</span></h5>
                                <hr />
                                <p className="card-text">
                                    Ora Inizio: <span className="text-danger"><strong>{turno.ora_inizio}</strong></span> - Ora Fine: <span className="text-danger"><strong>{turno.ora_fine}</strong></span><br />
                                    Automezzo: <span className="text-danger"><strong>{turno.automezzo}</strong></span>
                                </p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            © Copyright 2023 - <strong className="text-primary">Akrea S.p.a.</strong>
                        </div>
                        <div className="col-md-6">
                            <div className="text-md-end footer-links d-none d-md-block">
                                <a href="http://">developed by <strong className="text-primary">Kromix Soc. Coop.</strong></a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Personal;
