import React from 'react';
import { Link } from 'react-router-dom';

import './footer.styles.scss';

const Footer = () => {
	return (
		<div className="footer">

			<div className="container-fluid">
				<div className="row">
					<div className="col-md-6">
						© Copyright 2023 - <strong className="text-primary">Akrea S.p.a.</strong>
					</div>
					<div className="col-md-6">
						<div className="text-md-end footer-links d-none d-md-block">
							<Link href="#">developed by <strong className="text-primary">Kromix Soc. Coop.</strong></Link>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
};

export default Footer;