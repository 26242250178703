import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component';
import { selectLoading } from '../../../redux/features/features.selectors';
import { addServizi } from '../../../redux/features/servizi/servizi.thunk';

import './nuovo-servizio.styles.scss';
import FormServizi from '../../../components/form-servizi/form-servizi.component';
import { toast } from 'react-toastify';

const NuovoServizio = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector(selectLoading);

    const [campi, setCampi] = useState({
        id: 0,
        servizio: ''
    });

    const [errori, setErrori] = useState({
        servizio: false,
    });


    const handleChange = (e) => {
        const { value, name } = e.target;
        setCampi({ ...campi, [name]: value });
        setErrori({ servizio: false });
    }

    const inviaDati = async (e) => {
        e.preventDefault();

        if (campi.servizio === '') return setErrori({ ...errori, servizio: true });

        const result = await dispatch(addServizi(campi));

        if (!result.error) {
            toast.success('Servizio inserito con successo', {
                position: 'top-right'
            })
            navigate('/servizi');
        } else {
            toast.error(result.payload, {
                position: 'top-right'
            })
        }
    }

    return (loading) ? (
        <div className='attendere'>
            <Loader />
        </div>
    ) : (
        <div className="nuovo-servizio">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <FormServizi errori={errori} handleChange={handleChange} {...campi} inviaDati={inviaDati} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NuovoServizio;