import { createAsyncThunk } from "@reduxjs/toolkit";
import { logout } from "../../auth/auth.slice";
import { consuntivo, consuntivoAdd, consuntivoDelete, consuntivoEdit, consuntivoSearch } from "./consuntivo.api";

export const addConsuntivo = createAsyncThunk(
    'features/addConsuntivo',
    async (infoConsuntivo, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await consuntivoAdd(stato.auth.user.token, infoConsuntivo);
        if (response.esito === 1) {
            return response;
        }
        else if(response.esito === 0 && response.errorCode === 2)
        {
            return rejectWithValue('Ore dipendente gia presenti');
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

export const editConsuntivo = createAsyncThunk(
    'features/editConsuntivo',
    async (infoConsuntivo, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await consuntivoEdit(stato.auth.user.token, infoConsuntivo);
        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

/*export const getAutomezzo = createAsyncThunk(
    'features/getAutomezzo',
    async (id, { getState, rejectWithValue }) => {

        const stato = getState();
        const response = await dipendentiSingle(stato.auth.user.token, id);

        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)*/

export const getConsuntivo = createAsyncThunk(
    'features/consuntivo',
    async (pagina, { getState, rejectWithValue, dispatch }) => {

        const stato = getState();
        const response = await consuntivo(stato.auth.user.token, pagina);

        if (response.esito === 1) {
            return response;
        }
        else {
            if(response.code === 401)
            {
                dispatch(logout());
            }
            return rejectWithValue(response.error);
        }
    }
)

export const deleteConsuntivo = createAsyncThunk(
    'features/deleteConsuntivo',
    async (id, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await consuntivoDelete(stato.auth.user.token, id);
        if (response.esito === 1) {
            return response;
        }
        else if (response.esito === 2) {
            return rejectWithValue("Impossibile eliminare le ore per il dipendente");
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

export const searchConsuntivo = createAsyncThunk(
    'features/searchConsuntivo',
    async (info, { getState, rejectWithValue }) => {

        const stato = getState();
        const response = await consuntivoSearch(stato.auth.user.token, info.pagina, info.ricerca);

        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)