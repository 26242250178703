import { baseAPI } from "../../../config";

export const consuntivo = async (token, pagina) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "consuntivo-" + pagina, {
        cache: 'no-cache',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const consuntivoAdd = async (token, infoConsuntivo) => {
    return fetch(baseAPI + "consuntivo", {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
            dipendente: infoConsuntivo.dipendente,
            data_presenza: infoConsuntivo.data_presenza,
            presenza: infoConsuntivo.presenza,
            ordinario: infoConsuntivo.ordinario,
            straordinario: infoConsuntivo.straordinario,
            inizio: infoConsuntivo.inizio,
            fine: infoConsuntivo.fine,
            notturno: infoConsuntivo.notturno,
            malattia: infoConsuntivo.malattia,
            infortunio: infoConsuntivo.infortunio,
            note: infoConsuntivo.note
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const consuntivoEdit = async (token, infoConsuntivo) => {
    return fetch(baseAPI + "consuntivo/" + infoConsuntivo.id, {
        method: 'PATCH',
        cache: 'no-cache',
        body: JSON.stringify({
            dipendente: infoConsuntivo.dipendente,
            data_presenza: infoConsuntivo.data_presenza,
            presenza: infoConsuntivo.presenza,
            ordinario: infoConsuntivo.ordinario,
            straordinario: infoConsuntivo.straordinario,
            inizio: infoConsuntivo.inizio,
            fine: infoConsuntivo.fine,
            notturno: infoConsuntivo.notturno,
            malattia: infoConsuntivo.malattia,
            infortunio: infoConsuntivo.infortunio,
            note: infoConsuntivo.note
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const consuntivoDelete = async (token, id) => {
    return fetch(baseAPI + "consuntivo/" + id, {
        method: 'DELETE',
        cache: 'no-cache',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const consuntivoSearch = async (token, pagina, ricerca) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "consuntivo/search-" + pagina + '?' + new URLSearchParams(ricerca), {
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        
    }).then(response => response.json());
}