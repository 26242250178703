import React from 'react';

import './page-title.styles.scss';

const PageTitle = ({titolo}) => {
	return (
		<div className="page-title">
			<div className="row">
				<div className="col-8">
					<div className="page-title-box">
						<h4 className="page-title">{titolo}</h4>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageTitle;