import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectLoading } from '../../../redux/features/features.selectors';

import './aggiungi-consuntivo.styles.css';
import { toast } from 'react-toastify';
import Loader from '../../../components/loader/loader.component';
import { addConsuntivo } from '../../../redux/features/consuntivo/consuntivo.thunk';
import FormConsuntivo from '../../../components/form-consuntivo/form-consuntivo.component';

const AggiungiConsuntivo = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loading = useSelector(selectLoading);

	const [campi, setCampi] = useState({

		dipendente: '',
		presenza: '',
		ordinario: '',
		straordinario: '',
		notturno: '',
		malattia: '',
		infortunio: '',
		note: ''
	});

	const [errori, setErrori] = useState({
		dipendente: false,
		presenza: false
	});


	const handleChange = (e) => {
		const { value, name } = e.target;
		setCampi({ ...campi, [name]: value });
		setErrori({ dipendente: false, presenza: false });
	}

	const inviaDati = async (e) => {
		e.preventDefault();

		if (campi.dipendente === '') return setErrori({ ...errori, dipendente: true });
		if (campi.presenza === '') return setErrori({ ...errori, presenza: true });

		const result = await dispatch(addConsuntivo(campi));

		if (!result.error) {
			toast.success('Ore dipendente inserito con successo', {
				position: 'top-right'
			})
			navigate('/ferie');
		} else {
			toast.error(result.payload, {
				position: 'top-right'
			})
		}
	}

	return (loading) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (

		<div className="aggiungi-consuntivo">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<FormConsuntivo errori={errori} handleChange={handleChange} {...campi} inviaDati={inviaDati} />
						</div>
					</div>
				</div>
			</div>
		</div>

	);
};

export default AggiungiConsuntivo;