import { createAsyncThunk } from "@reduxjs/toolkit";
import { dipendenti, dipendentiAdd, dipendentiDelete, dipendentiEdit, dipendentiSearch } from "./dipendenti.api";
import { logout } from "../../auth/auth.slice";

export const addDipendente = createAsyncThunk(
    'features/addDipendente',
    async (infoDipendente, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await dipendentiAdd(stato.auth.user.token, infoDipendente);
        if (response.esito === 1) {
            return response;
        }
        else if(response.esito === 0 && response.errorCode === 2)
        {
            return rejectWithValue('Dipendente gia esistente');
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

export const editDipendente = createAsyncThunk(
    'features/editDipendente',
    async (infoDipendenti, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await dipendentiEdit(stato.auth.user.token, infoDipendenti);
        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

/*export const getAutomezzo = createAsyncThunk(
    'features/getAutomezzo',
    async (id, { getState, rejectWithValue }) => {

        const stato = getState();
        const response = await dipendentiSingle(stato.auth.user.token, id);

        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)*/

export const getDipendente = createAsyncThunk(
    'features/dipendenti',
    async (pagina, { getState, rejectWithValue, dispatch }) => {

        const stato = getState();
        const response = await dipendenti(stato.auth.user.token, pagina);

        if (response.esito === 1) {
            return response;
        }
        else {
            if(response.code === 401)
            {
                dispatch(logout());
            }
            return rejectWithValue(response.error);
        }
    }
)

export const deleteDipendente = createAsyncThunk(
    'features/deleteDipendente',
    async (id, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await dipendentiDelete(stato.auth.user.token, id);
        if (response.esito === 1) {
            return response;
        }
        else if (response.esito === 2) {
            return rejectWithValue("Impossibile eliminare il dipendente");
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

export const searchDipendente = createAsyncThunk(
    'features/searchDipendente',
    async (info, { getState, rejectWithValue }) => {

        const stato = getState();
        const response = await dipendentiSearch(stato.auth.user.token, info.pagina, info.ricerca);

        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)