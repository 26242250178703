import { createSlice } from "@reduxjs/toolkit";
import { getAttivita } from "./attivita/attivita.thunk";
import { getServizi } from "./servizi/servizi.thunk";
import { addAutomezzo, getAutomezzi, searchAutomezzi } from "./automezzi/automezzi.thunk";
import { getTurni } from "./features.thunk";
import { getDipendente } from "./dipendenti/dipendenti.thunk";
import { getFerie, searchFerie } from "./ferie/ferie.thunk";
import { addTurno, searchTurni } from "./turni/turni.thunk";
import { getConsuntivo } from "./consuntivo/consuntivo.thunk";

export const featuresSlice = createSlice({
    name: 'features',
    initialState: {
        error: null,
        loading: false,
        dati: null,
    },
    reducers: {

    },
    extraReducers: {
        [getTurni.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [getTurni.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [getTurni.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = true;
            state.dati = null;
        },
        [addAutomezzo.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [addAutomezzo.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [addAutomezzo.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        },
        [getAutomezzi.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [getAutomezzi.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [getAutomezzi.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        },
        [searchAutomezzi.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [searchAutomezzi.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [searchAutomezzi.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        },
        [searchFerie.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [searchFerie.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [searchFerie.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        },
        [getAttivita.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [getAttivita.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [getAttivita.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        },
        [getFerie.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [getFerie.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [getFerie.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        },
        [getConsuntivo.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [getConsuntivo.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [getConsuntivo.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        },
        [getServizi.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [getServizi.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [getServizi.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        },
        [getDipendente.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [getDipendente.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [getDipendente.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        },
        [addTurno.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [addTurno.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [addTurno.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        },
        [searchTurni.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.dati = null;
        },
        [searchTurni.fulfilled]: (state, action) => {
            state.error = null;
            state.loading = false;
            state.dati = action.payload
        },
        [searchTurni.rejected]: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.dati = null;
        },
    }
})

export default featuresSlice.reducer;