import { createAsyncThunk } from "@reduxjs/toolkit";
import { servizi, serviziAdd, serviziDelete,} from "./servizi.api";
import { logout } from "../../auth/auth.slice";

export const addServizi = createAsyncThunk(
    'features/addServizi',
    async (infoServizi, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await serviziAdd(stato.auth.user.token, infoServizi);
        if (response.esito === 1) {
            return response;
        }
        else if(response.esito === 0 && response.errorCode === 2)
        {
            return rejectWithValue('Servizio gia esistente');
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

export const getServizi = createAsyncThunk(
    'features/servizi',
    async (pagina, { getState, rejectWithValue, dispatch }) => {

        const stato = getState();
        const response = await servizi(stato.auth.user.token, pagina);

        if (response.esito === 1) {
            return response;
        }
        else {
            if(response.code === 401)
            {
                dispatch(logout());
            }
            return rejectWithValue(response.error);
        }
    }
)

export const deleteServizi = createAsyncThunk(
    'features/deleteServizi',
    async (id, { getState, rejectWithValue }) => {
        const stato = getState();
        const response = await serviziDelete(stato.auth.user.token, id);
        if (response.esito === 1) {
            return response;
        }
        else if (response.esito === 2) {
            return rejectWithValue("Impossibile eliminare il servizio");
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)
