import { createAsyncThunk } from "@reduxjs/toolkit";
import { turni } from '../features/features.api';



export const getTurni = createAsyncThunk(
    'features/getTurni',
    async (pagina, { getState, rejectWithValue }) => {

        const stato = getState();
        const response = await turni(stato.auth.user.token, pagina);

        if (response.esito === 1) {
            return response;
        }
        else {
            return rejectWithValue(response.error);
        }
    }
)

