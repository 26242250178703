import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteDipendente, getDipendente } from '../../redux/features/dipendenti/dipendenti.thunk';

import './tabella-dipendenti.styles.scss';

const TabellaDipendenti = ({ dipendenti }) => {

	const dispatch = useDispatch();

	const del = async (id) => {

		if(window.confirm("Sei sicuro di voler eliminare questo Dipendente?"))
		{
			const result = await dispatch(deleteDipendente(id));

			if(result.error)
			{
				toast.error(result.payload);
			}
			else
			{
				toast.success("Dipendente eliminato con successo");
				dispatch(getDipendente(1));
			}
		}
	}

	return (
		<div className="tabella-dipendenti">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">

							<table className="table table-striped mb-0">
								<thead className="table-dark">
									<tr>
										<th scope="col">Cognome</th>
										<th scope="col">Nome</th>
										<th className="text-center" scope="col">Data di nascita</th>
										<th scope="col">Codice Fiscale</th>
										<th scope="col">Email</th>
										<th scope="col">Telefono</th>
										<th className="text-center" scope="col" colSpan="3"></th>
									</tr>
								</thead>
								<tbody>
									{
										(dipendenti && dipendenti.length > 0) ?
											dipendenti.map((dipendente) => (
												<tr key={dipendente.id}>
													<th scope="row">{dipendente.cognome}</th>
													<td>{dipendente.nome}</td>
													<td className="text-center">{dipendente.nascita}</td>
													<td>{dipendente.codice_fiscale}</td>
													<td>{dipendente.mail}</td>
													<td>{dipendente.telefono}</td>

													<td className='text-center'>
														<Link to={`/dipendenti/edit/${dipendente.id}`} className="btn btn-warning" title='Modifica Dipendente'><i className="uil uil-edit"></i></Link> &nbsp;
														<Link to="#dipendenti" onClick={() => del(dipendente.id)} className="btn btn-danger" title='Elimina Dipendente'><i className="uil uil-times-square"></i></Link> &nbsp;
													</td>
												</tr>
											))
											: (<tr>
												<td colSpan='7'>Nessun elemento trovato</td>
											</tr>)

									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TabellaDipendenti;