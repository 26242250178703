import React from 'react';
import { Link } from 'react-router-dom';

import './menu-mobile.styles.scss';

const MenuMobile = () => {
	return (
		<div className="menu-mobile">
			<div className="dropdown">
				<button className="button-toggle-menu" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
					<i className="mdi mdi-menu"></i>
				</button>
				<div className="dropdown-menu mobile-menu" aria-labelledby="dropdownMenuButton">

					<ul className="side-nav">
						<li className="side-nav-title side-nav-item">Gestione Turni</li>

						<li className="side-nav-item">
							<Link to="/" className="side-nav-link">
								<i className="uil-calender"></i>
								<span> I miei turni</span>
							</Link>
						</li>

					</ul>
				</div>
			</div>
		</div>
	);
};

export default MenuMobile;