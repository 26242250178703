import React from 'react';

import './form-servizi.styles.scss';
import { Link } from 'react-router-dom';

const FormServizi = ({ errori, handleChange, servizio, inviaDati }) => {
    return (
        <div className='form-servizi'>
            <form action="" id="" method="post">
                <p className="text-primary font-14"><strong>Aggiungi una nuovo servizio</strong></p>
                <hr />
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                        <div className="mb-4">
                            <label htmlFor="servizio" className="form-label">Servizio</label>
                            <input className={`form-control ${errori.nome ? 'is-invalid' : null}`} id="servizio" onChange={handleChange} type="text" name="servizio" value={servizio || ''} required />
                            <div className="invalid-feedback">
                                Inserire un nome per il servizio
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4"></div>
                </div>

                <br />
                <br />

                <div className="row">
					<div className="col-lg-4"></div>
					<div className="col-lg-4 text-center">
						<Link to="/servizi" className="btn btn-danger">Torna Indietro</Link> &nbsp;&nbsp;
                        <button type="button" onClick={inviaDati} className="btn btn-success">Salva Dati</button>
					</div>
					<div className="col-lg-4"></div>

				</div>

            </form>
        </div>

    );
};

export default FormServizi;