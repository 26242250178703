import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectDati, selectLoading } from '../../redux/features/features.selectors';
import { selectTurni } from '../../redux/features/features.selectors';
import { getTurni } from '../../redux/features/features.thunk';
import Loader from '../../components/loader/loader.component';

import './turni.styles.scss';
import TabellaTurni from '../../components/tabella-turni/tabella-turni.component';
import { useState } from 'react';
import { getPagination } from '../../utilities/utility';
import { searchTurni } from '../../redux/features/turni/turni.thunk';
import { baseAPI } from '../../config';
import { useLazyGetExcelQuery } from '../../redux/features/features.query';
import { Link } from 'react-router-dom';

const Turni = () => {

	const dispatch = useDispatch();

	const [getExcel] = useLazyGetExcelQuery();

	const dati = useSelector(selectDati);
	const turni = useSelector(selectTurni);
	const loading = useSelector(selectLoading);
	const [active, setActive] = useState(1);
	const [search, setSearch] = useState({
		turni: 0,
		servizio: 0,
		attivita: 0,
		dal: '',
		al: '',
		automezzo: 0,
		dipendente: 0
	})

	const pagine = getPagination(dati?.pagina, dati?.pagine, 4);

	const downloadExcel = () => {
		getExcel().unwrap().then((res) => {
			window.open(`${baseAPI}../api/public/storage/export/${res.filename}`, '_self', 'noreferrer');
		}).catch((err) => {
			//toast.error();
		});
	}

	useEffect(() => {
		dispatch(getTurni(1));
	}, [dispatch]);

	const paginate = (pagina) => {
		setActive(pagina);
		dispatch(getTurni(pagina));
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
		setSearch({ ...search, [name]: value })
	}

	const handleCerca = async () => {
		await dispatch(searchTurni({
			pagina: 1,
			ricerca: search
		}))
	}

	return (loading) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		<div className="turni">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<form action="" id="" method="get">
								<p className="text-primary font-14"><strong>Cerca un turno</strong></p>
								<hr />

								<div className="row">
									<div className="col-lg-2">
										<div className="mb-3">
											<label htmlFor="turni" className="form-label">Turnazione</label>
											<select className="form-select" onChange={handleChange} id="turni" name="turni">
												<option value="0">Seleziona</option>
												<option value="1">Primo Turno</option>
												<option value="2">Turno Pomeridiano</option>
												<option value="3">Raccolta Differenziata</option>
											</select>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="mb-3">
											<label htmlFor="id_servizio" className="form-label">Tipologia Servizio</label>
											<select className="form-select" onChange={handleChange} id="id_servizio" name="id_servizio">
												<option value="0">Seleziona</option>
												<option value="18">Isola Ecologica</option>
												<option value="19">Raccolta differenziata</option>
												<option value="20">RSU</option>
												<option value="21">Spazzamento</option>
												<option value="22">Igiene Urbana</option>
												<option value="23">Officina e servizi generali</option>
												<option value="24">Servizi generali</option>
											</select>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="mb-3">
											<label htmlFor="id_attivita" className="form-label">Tipologia Attivita'</label>
											<select className="form-select" id="id_attivita" onChange={handleChange} name="id_attivita">
												<option value="0">Seleziona</option>
												<option value="17">Multimateriale</option>
												<option value="21">Cartone</option>
												<option value="23">Organico</option>
												<option value="25">Porta a Porta Utenze</option>
												<option value="26">Carta</option>
												<option value="29">Ingombranti - RAEE - Legno</option>
												<option value="32">Rifiuti ortofrutticoli</option>
												<option value="33">Utenze ortofrutticoli</option>
												<option value="34">Piazzale della Pace</option>
												<option value="35">Via Saffo</option>
												<option value="37">Poggiopudano</option>
												<option value="38">Poggioverde - S.Cosmo e Dmiano - Sett. 291- Benincasa</option>
												<option value="39">Magna Grecia - Parco Carrara</option>
												<option value="40">Margherita - Lampanaro - </option>
												<option value="41">Sfalci - Ingombranti - RSU</option>
												<option value="43">Rifiuti RD</option>
												<option value="45">Referente generale</option>
												<option value="46">Referente avvio RD UND Enti pubblici</option>
												<option value="47">Referente Isole ecologiche e ritiri a domicilio</option>
												<option value="48">Referente area industriale e periferie</option>
												<option value="49">Referente distribuzione per avvio RD</option>
												<option value="50">Referente controllo RD stradale - Ecoisole</option>
												<option value="51">Raccolta organico 1100</option>
												<option value="55">Margherita Soprana</option>
												<option value="56">Pulizia delle aree mercatali</option>
												<option value="57">Pulizia spiagge</option>
												<option value="58">Svuotamento cestini</option>
												<option value="59">Manutenzione interna</option>
												<option value="60">Spazzamento meccanizzato</option>
												<option value="61">Spazzamento manuale</option>
												<option value="68">RC1</option>
												<option value="69">RC2</option>
												<option value="70">RC3</option>
												<option value="71">RC4</option>
												<option value="72">Pac 1</option>
												<option value="73">Pac 2</option>
												<option value="74">Pac 3</option>
												<option value="75">Papanice</option>
												<option value="76">RC5</option>
												<option value="77">RC6</option>
												<option value="78">RC8</option>
												<option value="78">lavaggio strade e piazze</option>
												<option value="80">Presidio Trasbordo RSU</option>
											</select>
										</div>
									</div>
									<div className="col-lg-2">
										<div className="mb-3">
											<label htmlFor="dal" className="form-label">Dal Giorno</label>
											<input className="form-control" id="dal" type="date" onChange={handleChange} name="dal" />
										</div>
									</div>
									<div className="col-lg-2">
										<div className="mb-3">
											<label htmlFor="al" className="form-label">Al Giorno</label>
											<input className="form-control" id="al" type="date" onChange={handleChange} name="al" />
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-4">
										<div className="mb-3">
											<label htmlFor="id_automezzo" className="form-label">Automezzo</label>
											<select className="form-select" onChange={handleChange} id="id_automezzo" name="id_automezzo">
												<option value="0">Seleziona</option>
												<option value="40">BH884BS</option>
												<option value="43">BH885BS</option>
												<option value="44">BH886BS</option>
												<option value="45">BH891BS</option>
												<option value="46">CJ186AZ</option>
												<option value="47">CJ187AZ</option>
												<option value="48">CJ603AY</option>
												<option value="49">CJ602AY</option>
												<option value="50">CS665DS</option>
												<option value="51">EM835JM</option>
												<option value="52">EM834JM</option>
												<option value="53">FH377WV</option>
												<option value="54">FH378WV</option>
												<option value="55">FH379WV</option>
												<option value="56">FH380WV</option>
												<option value="57">FH381WV</option>
												<option value="58">FH382WV</option>
												<option value="59">FH383WV</option>
												<option value="60">FH384WV</option>
												<option value="61">FH385WV</option>
												<option value="62">FH386WV</option>
												<option value="63">FH387WV</option>
												<option value="64">FH388WV</option>
												<option value="65">FH389WV</option>
												<option value="66">FH390WV</option>
												<option value="67">FH391WV</option>
												<option value="68">FH392WV</option>
												<option value="69">FH393WV</option>
												<option value="70">FH394WV</option>
												<option value="71">FH397WV</option>
												<option value="72">FH395WV</option>
												<option value="73">FH396WV</option>
												<option value="74">FH400WV</option>
												<option value="75">FH401WV</option>
												<option value="76">EK093TC</option>
												<option value="77">CZ346771</option>
												<option value="78">EJ886TE</option>
												<option value="79">AL537EZ</option>
												<option value="80">AFZ965</option>
												<option value="81">AFZ966</option>
												<option value="82">KR003601</option>
												<option value="83">ACP075</option>
												<option value="84">ACP076</option>
												<option value="85">AZ537V</option>
												<option value="86">AAB490</option>
												<option value="87">FR285HD</option>
												<option value="88">FR287HD</option>
												<option value="89">FR290HD</option>
												<option value="90">CX102KD</option>
												<option value="91">CX103KD</option>
												<option value="92">DJ225FN</option>
												<option value="93">AX966DH</option>
												<option value="94">BX625ZE</option>
												<option value="95">EC469BS</option>
												<option value="96">EX289GZ</option>
												<option value="97">FV412ZL</option>
												<option value="98">GJ562DD</option>
												<option value="99">DV957NV</option>
												<option value="100">FZ789MD</option>
												<option value="101">GG547EK</option>
												<option value="102">GD509KX</option>
												<option value="103">00 000 00</option>

											</select>
										</div>
									</div>
									<div className="col-lg-8">
										<div className="mb-3">
											<label htmlFor="id_dipendente" className="form-label">Dipendente</label>
											<select required className="form-select" onChange={handleChange} name="id_dipendente" id="id_dipendente">
												<option value="0">Seleziona</option>
												<option value="1">Amoruso E.</option>
												<option value="2">Amoruso M.</option>
												<option value="3">Cirisano</option>
												<option value="4">Gerace S.</option>
												<option value="5">Ienopoli</option>
												<option value="6">Mungari G.</option>
												<option value="7">Campagna</option>
												<option value="8">Corigliano F.</option>
												<option value="9">Manfredi</option>
												<option value="10">Sulla</option>
												<option value="11">Torchia</option>
												<option value="12">Valenti</option>
												<option value="13">Elia</option>
												<option value="14">Gallina</option>
												<option value="15">Caruso</option>
												<option value="16">Cetera</option>
												<option value="17">Grande</option>
												<option value="18">Pignolo</option>
												<option value="19">Del Poggetto</option>
												<option value="20">Perri</option>
												<option value="21">Ruggiero</option>
												<option value="22">Asteriti</option>
												<option value="23">Catanzaro P.</option>
												<option value="24">Tisci</option>
												<option value="25">Infante</option>
												<option value="26">Vrenna</option>
												<option value="27">Russo</option>
												<option value="28">Taschera</option>
												<option value="29">Alessi</option>
												<option value="30">Cammariere</option>
												<option value="31">Catanzaro G.</option>
												<option value="32">Gaetani</option>
												<option value="33">Lumare A.</option>
												<option value="34">Riga F.</option>
												<option value="35">Vona V.</option>
												<option value="36">Corigliano G.</option>
												<option value="37">De Fazio</option>
												<option value="38">Loria</option>
												<option value="39">Pugliese</option>
												<option value="40">Rizzo</option>
												<option value="41">Sarcone</option>
												<option value="42">Arcuri</option>
												<option value="43">Catanzaro F.</option>
												<option value="44">Correale</option>
												<option value="45">De Sole</option>
												<option value="46">Infusino</option>
												<option value="47">Lumare M.</option>
												<option value="48">Muscatello</option>
												<option value="49">Raso</option>
												<option value="50">Riga D.</option>
												<option value="51">Riolo</option>
												<option value="52">Valente</option>
												<option value="53">Oliverio</option>
												<option value="54">Proietto L.</option>
												<option value="55">Lomoro</option>
												<option value="56">Manica</option>
												<option value="57">Noce</option>
												<option value="58">Passalacqua</option>
												<option value="59">Corigliano A.</option>
												<option value="60">Esposito</option>
												<option value="61">Galea</option>
												<option value="62">Graziano V.</option>
												<option value="63">Leo</option>
												<option value="64">Paglia</option>
												<option value="65">Pasqua</option>
												<option value="66">Scarriglia</option>
												<option value="67">Spano'</option>
												<option value="68">Attisano</option>
												<option value="69">Catanzaro D.</option>
												<option value="70">Cava</option>
												<option value="71">Chimirri</option>
												<option value="72">Clerico'</option>
												<option value="73">Colurcio</option>
												<option value="74">Crugliano</option>
												<option value="75">Federico</option>
												<option value="76">Gallo</option>
												<option value="77">Gerace G.</option>
												<option value="78">Gerace</option>
												<option value="79">Madia</option>
												<option value="80">Mazza</option>
												<option value="81">Milone</option>
												<option value="82">Mungari A.</option>
												<option value="83">Panucci N.</option>
												<option value="84">Proietto S.</option>
												<option value="85">Ristagno</option>
												<option value="86">Rocca</option>
												<option value="87">Rota</option>
												<option value="88">Varano</option>
												<option value="89">Vona G.</option>
												<option value="90">Graziano C.</option>
												<option value="91">Nicoletta</option>
												<option value="92">Bonaccio</option>
												<option value="93">Gerace Salvatore</option>
												<option value="94">Regalino</option>
												<option value="95">Marino</option>
												<option value="96">Maiolo</option>
											</select>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			<br />

			<div className="row">
				<div className="col-lg-12 text-center">
					<button type="button" onClick={handleCerca} className="btn btn-success">Cerca</button>
				</div>
			</div>

			<hr />

			<div className="row">
				<div className="col-md-11 text-end">
					<Link to='/turni/new' className="btn btn-info btn-sm btn-block">Aggiungi</Link>
				</div>
				<div className="col-md-1 text-end">
					<button onClick={() => downloadExcel()} className="btn-success btn btn-sm text-black">Scarica Excel</button>
				</div>
			</div>
			<br />

			<TabellaTurni turni={turni} />
			<div className="row">
				<div className="col-md-4"></div>
				<div className="col-md-4">
					<nav>
						<ul className="pagination pagination-rounded mb-0 justify-content-center">

							<li className="page-item">
								<button className="page-link" onClick={pagine.first ? () => paginate(pagine.first) : null} aria-label="Previous">
									<span aria-hidden="true">&laquo;</span>
								</button>
							</li>
							{
								pagine.before.map((num) => (
									<li key={num} className={"page-item " + ((active === num) ? 'active' : null)}><button className="page-link" onClick={() => paginate(num)}>{num}</button></li>
								))
							}
							<li className={"page-item " + ((active === dati?.pagina) ? 'active' : null)}><button className="page-link">{dati?.pagina}</button></li>
							{
								pagine.after.map((num) => (
									<li key={num} className={"page-item " + ((active === num) ? 'active' : null)}><button className="page-link" onClick={() => paginate(num)}>{num}</button></li>
								))
							}

							<li className="page-item">
								<button className="page-link" onClick={pagine.last ? () => paginate(pagine.last) : null} aria-label="Next">
									<span aria-hidden="true">&raquo;</span>
								</button>
							</li>
						</ul>
					</nav>
				</div>
				<div className="col-md-4"></div>
			</div>
		</div>
	);
};

export default Turni;