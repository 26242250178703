import React, { useEffect, useState } from 'react';
import TabellaServizi from '../../components/tabella-servizi/tabella-servizi.component';
import Loader from '../../components/loader/loader.component';
import { getServizi } from '../../redux/features/servizi/servizi.thunk';
import { selectServizi, selectDati, selectLoading } from '../../redux/features/features.selectors';
import { getPagination } from '../../utilities/utility';

import './servizi.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const Servizi = () => {

	const dispatch = useDispatch();
	const dati = useSelector(selectDati);
	const servizi = useSelector(selectServizi);
	const loading = useSelector(selectLoading);
	const [active, setActive] = useState(1);


	
	const pagine = getPagination(dati?.pagina, dati?.pagine, 4);

	useEffect(() => {
		dispatch(getServizi(1));
	}, [dispatch]);


	const paginate = (pagina) => {
		setActive(pagina);
		dispatch(getServizi(pagina));
	}



	return (loading) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		(dati) ? (

			<div className="servizi">
				<div className="row">
					<div className="offset-11 col-md-1">
						<Link to='/servizi/new' className="btn btn-info btn-block">Aggiungi</Link>
					</div>
				</div>
				<br />
				<TabellaServizi servizi={servizi} />

				<div className="row">
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<nav>
							<ul className="pagination pagination-rounded mb-0 justify-content-center">

								<li className="page-item">
									<button className="page-link" onClick={pagine.first ? () => paginate(pagine.first) : null} aria-label="Previous">
										<span aria-hidden="true">&laquo;</span>
									</button>
								</li>
								{
									pagine.before.map((num) => (
										<li key={num} className={"page-item " + ((active === num) ? 'active' : null)}><button className="page-link" onClick={() => paginate(num)}>{num}</button></li>
									))
								}
								<li className={"page-item " + ((active === dati.pagina) ? 'active' : null)}><button className="page-link">{dati.pagina}</button></li>
								{
									pagine.after.map((num) => (
										<li key={num} className={"page-item " + ((active === num) ? 'active' : null)}><button className="page-link" onClick={() => paginate(num)}>{num}</button></li>
									))
								}

								<li className="page-item">
									<button className="page-link" onClick={pagine.last ? () => paginate(pagine.last) : null} aria-label="Next">
										<span aria-hidden="true">&raquo;</span>
									</button>
								</li>
							</ul>
						</nav>
					</div>
					<div className="col-md-4"></div>
				</div>
				<br />
			</div>

		) : null
	);
};

export default Servizi;