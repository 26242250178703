import React, { useEffect, useState } from 'react';
import TabellaFerie from '../../components/tabella-ferie/tabella-ferie.component';
import Loader from '../../components/loader/loader.component';
import { getFerie, searchFerie } from '../../redux/features/ferie/ferie.thunk';
import { selectFerie, selectDati, selectLoading } from '../../redux/features/features.selectors';

import './ferie.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getPagination } from '../../utilities/utility';

const Ferie = () => {
	const dispatch = useDispatch();
	const dati = useSelector(selectDati);
	const ferie = useSelector(selectFerie);
	const loading = useSelector(selectLoading);
	const [active, setActive] = useState(1);
	const [search, setSearch] = useState({
		id_dipendente: '',
		id_tipo: ''
	})

	const pagine = getPagination(dati?.pagina, dati?.pagine, 4);

	useEffect(() => {
		dispatch(getFerie(1));
	}, [dispatch]);

	const paginate = (pagina) => {
		setActive(pagina);
		dispatch(getFerie(pagina));
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
		setSearch({ ...search, [name]: value })
	}

	const handleCerca = async () => {
		await dispatch(searchFerie({
			pagina: 1,
			ricerca: search
		}))
	}

	return (loading) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		(dati) ? (

			<div className="ferie">

				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-body">
								<form action="" id="" method="get">
									<p className="text-primary font-14"><strong>Cerca un dipendente</strong></p>
									<hr />

									<div className="row">

										<div className="col-lg-6">
											<div className="mb-3">
												<label htmlFor="id_dipendente" className="form-label">Dipendente</label>
												<select required className="form-select" onChange={handleChange} name="id_dipendente" id="id_dipendente">
													<option value="0">Seleziona</option>
													<option value="1">Amoruso E.</option>
													<option value="2">Amoruso M.</option>
													<option value="3">Cirisano</option>
													<option value="4">Gerace S.</option>
													<option value="5">Ienopoli</option>
													<option value="6">Mungari G.</option>
													<option value="7">Campagna</option>
													<option value="8">Corigliano F.</option>
													<option value="9">Manfredi</option>
													<option value="10">Sulla</option>
													<option value="11">Torchia</option>
													<option value="12">Valenti</option>
													<option value="13">Elia</option>
													<option value="14">Gallina</option>
													<option value="15">Caruso</option>
													<option value="16">Cetera</option>
													<option value="17">Grande</option>
													<option value="18">Pignolo</option>
													<option value="19">Del Poggetto</option>
													<option value="20">Perri</option>
													<option value="21">Ruggiero</option>
													<option value="22">Asteriti</option>
													<option value="23">Catanzaro P.</option>
													<option value="24">Tisci</option>
													<option value="25">Infante</option>
													<option value="26">Vrenna</option>
													<option value="27">Russo</option>
													<option value="28">Taschera</option>
													<option value="29">Alessi</option>
													<option value="30">Cammariere</option>
													<option value="31">Catanzaro G.</option>
													<option value="32">Gaetani</option>
													<option value="33">Lumare A.</option>
													<option value="34">Riga F.</option>
													<option value="35">Vona V.</option>
													<option value="36">Corigliano G.</option>
													<option value="37">De Fazio</option>
													<option value="38">Loria</option>
													<option value="39">Pugliese</option>
													<option value="40">Rizzo</option>
													<option value="41">Sarcone</option>
													<option value="42">Arcuri</option>
													<option value="43">Catanzaro F.</option>
													<option value="44">Correale</option>
													<option value="45">De Sole</option>
													<option value="46">Infusino</option>
													<option value="47">Lumare M.</option>
													<option value="48">Muscatello</option>
													<option value="49">Raso</option>
													<option value="50">Riga D.</option>
													<option value="51">Riolo</option>
													<option value="52">Valente</option>
													<option value="53">Oliverio</option>
													<option value="54">Proietto L.</option>
													<option value="55">Lomoro</option>
													<option value="56">Manica</option>
													<option value="57">Noce</option>
													<option value="58">Passalacqua</option>
													<option value="59">Corigliano A.</option>
													<option value="60">Esposito</option>
													<option value="61">Galea</option>
													<option value="62">Graziano V.</option>
													<option value="63">Leo</option>
													<option value="64">Paglia</option>
													<option value="65">Pasqua</option>
													<option value="66">Scarriglia</option>
													<option value="67">Spano'</option>
													<option value="68">Attisano</option>
													<option value="69">Catanzaro D.</option>
													<option value="70">Cava</option>
													<option value="71">Chimirri</option>
													<option value="72">Clerico'</option>
													<option value="73">Colurcio</option>
													<option value="74">Crugliano</option>
													<option value="75">Federico</option>
													<option value="76">Gallo</option>
													<option value="77">Gerace G.</option>
													<option value="78">Gerace</option>
													<option value="79">Madia</option>
													<option value="80">Mazza</option>
													<option value="81">Milone</option>
													<option value="82">Mungari A.</option>
													<option value="83">Panucci N.</option>
													<option value="84">Proietto S.</option>
													<option value="85">Ristagno</option>
													<option value="86">Rocca</option>
													<option value="87">Rota</option>
													<option value="88">Varano</option>
													<option value="89">Vona G.</option>
													<option value="90">Graziano C.</option>
													<option value="91">Nicoletta</option>
													<option value="92">Bonaccio</option>
													<option value="93">Gerace Salvatore</option>
													<option value="94">Regalino</option>
													<option value="95">Marino</option>
													<option value="96">Maiolo</option>
												</select>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="mb-3">
												<label htmlFor="id_tipo" className="form-label">Tipologia Permesso</label>
												<select className="form-select" onChange={handleChange} id="id_tipo" name="id_tipo">
													<option value="0">Seleziona</option>
													<option value="1">F - Ferie</option>
													<option value="2">PR - Permesso Retribuito</option>
													<option value="3">PR TRIB - Permesso Retribuito Tribunale</option>
													<option value="4">M - Malattia</option>
													<option value="5">RC - Riposo Compensativo</option>
													<option value="6">RS - Riposo Settimanale</option>
													<option value="7">DS - Donazione Sangue</option>
													<option value="8">FE - Ferie Estive</option>
													<option value="9">VM - Visita Medica</option>
													<option value="10">FM - Verie Matrimoniali</option>
													<option value="11">MC - Malattia Covid</option>
													<option value="12">INF - Infortunio</option>
													<option value="13">RIC - Ricovero</option>
													<option value="14">A - Assente</option>
													<option value="15">PR - 104</option>
													<option value="16">PR - Sindacale</option>
													<option value="17">ASF - Congedo per assistere il familiare con disabilità grave</option>
													<option value="18">CP - Congedo parentale</option>
												</select>
											</div>
										</div>

									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

				<br />

				<div className="row">
					<div className="col-lg-12 text-center">
						<button type="button" onClick={handleCerca} className="btn btn-success">Cerca</button>
					</div>
				</div>

				<hr />

				<div className="row">
					<div className="col-lg-12">
						<TabellaFerie ferie={ferie} />
					</div>
				</div>

				<div className="row">
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<nav>
							<ul className="pagination pagination-rounded mb-0 justify-content-center">

								<li className="page-item">
									<button className="page-link" onClick={pagine.first ? () => paginate(pagine.first) : null} aria-label="Previous">
										<span aria-hidden="true">&laquo;</span>
									</button>
								</li>
								{
									pagine.before.map((num) => (
										<li key={num} className={"page-item " + ((active === num) ? 'active' : null)}><button className="page-link" onClick={() => paginate(num)}>{num}</button></li>
									))
								}
								<li className={"page-item " + ((active === dati?.pagina) ? 'active' : null)}><button className="page-link">{dati?.pagina}</button></li>
								{
									pagine.after.map((num) => (
										<li key={num} className={"page-item " + ((active === num) ? 'active' : null)}><button className="page-link" onClick={() => paginate(num)}>{num}</button></li>
									))
								}

								<li className="page-item">
									<button className="page-link" onClick={pagine.last ? () => paginate(pagine.last) : null} aria-label="Next">
										<span aria-hidden="true">&raquo;</span>
									</button>
								</li>
							</ul>
						</nav>
					</div>
					<div className="col-md-4"></div>
				</div>
			</div>

		) : null
	);
};

export default Ferie;