import { baseAPI } from "../../../config";

export const automezzi = async (token, pagina) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "automezzi-" + pagina, {
        cache: 'no-cache',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const automezziAdd = async (token, infoAutomezzo) => {
    return fetch(baseAPI + "automezzi", {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
            categoria_automezzo: infoAutomezzo.categoria_automezzo,
            targa: infoAutomezzo.targa,
            tipologia: infoAutomezzo.tipologia,
            mc: infoAutomezzo.mc,
            portata: infoAutomezzo.portata,
            disponibilita: infoAutomezzo.disponibilita,
            scadenza_leasingnoleggio: infoAutomezzo.scadenza_leasingnoleggio,
            albo_rifiuti: infoAutomezzo.albo_rifiuti,
            crono_tachigrafo: infoAutomezzo.crono_tachigrafo,
            note: infoAutomezzo.note,
            attivo: 1
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const automezziEdit = async (token, infoAutomezzo) => {
    return fetch(baseAPI + "automezzi/" + infoAutomezzo.id, {
        method: 'PATCH',
        cache: 'no-cache',
        body: JSON.stringify({
            categoria_automezzo: infoAutomezzo.categoria_automezzo,
            targa: infoAutomezzo.targa,
            tipologia: infoAutomezzo.tipologia,
            mc: infoAutomezzo.mc,
            portata: infoAutomezzo.portata,
            disponibilita: infoAutomezzo.disponibilita,
            scadenza_leasingnoleggio: infoAutomezzo.scadenza_leasingnoleggio,
            albo_rifiuti: infoAutomezzo.albo_rifiuti,
            crono_tachigrafo: infoAutomezzo.crono_tachigrafo,
            note: infoAutomezzo.note,
            attivo: 1
        }),
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const automezziDelete = async (token, id) => {
    return fetch(baseAPI + "automezzi/" + id, {
        method: 'DELETE',
        cache: 'no-cache',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(response => response.json());
}

export const automezziSearch = async (token, pagina, ricerca) => {

    pagina = (pagina) ? pagina : 1;

    return fetch(baseAPI + "automezzi/search-" + pagina + '?' + new URLSearchParams(ricerca), {
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        
    }).then(response => response.json());
}