import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../../components/loader/loader.component';
import TabellaAutomezzi from '../../components/tabella-automezzi/tabella-automezzi.component';
import { getAutomezzi, searchAutomezzi } from '../../redux/features/automezzi/automezzi.thunk';
import { selectAutomezzi, selectDati, selectLoading } from '../../redux/features/features.selectors';
import { getPagination } from '../../utilities/utility';

import './automezzi.styles.scss';

const ListaAutomezzi = () => {

	const dispatch = useDispatch();
	const dati = useSelector(selectDati);
	const automezzi = useSelector(selectAutomezzi);
	const loading = useSelector(selectLoading);
	const [active, setActive] = useState(1);
	const [search, setSearch] = useState({
		categoria_automezzo: '',
		targa: ''
	})

	const { categoria_automezzo, targa } = search;

	const pagine = getPagination(dati?.pagina, dati?.pagine, 4);

	useEffect(() => {
		dispatch(getAutomezzi(1));
	}, [dispatch]);

	const paginate = (pagina) => {
		setActive(pagina);
		dispatch(getAutomezzi(pagina));
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
		setSearch({ ...search, [name]: value })
	}

	const handleCerca = async () => {
		await dispatch(searchAutomezzi({
			pagina: 1,
			ricerca: search
		}))
	}

	return (loading) ? (
		<div className='attendere'>
			<Loader />
		</div>
	) : (
		(dati) ? (
			<div className="Lista-automezzi">

				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-body">
								<form action="" id="" method="get">
									<p className="text-primary font-14"><strong>Cerca un Automezzo</strong></p>
									<hr />

									<div className="row">
										<div className="col-lg-3"></div>
										<div className="col-lg-3">
											<div className="mb-3">
												<label htmlFor="categoria_automezzo" className="form-label">Categoria Automezzo</label>
												<input type="text" onChange={handleChange} className="form-control" value={categoria_automezzo} name="categoria_automezzo" id="categoria_automezzo" />
											</div>
										</div>
										<div className="col-lg-3">
											<div className="mb-3">
												<label htmlFor="targa" className="form-label">Targa</label>
												<input type="text" onChange={handleChange} className="form-control" value={targa} name="targa" id="targa" />
											</div>
										</div>
										<div className="col-lg-3"></div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

				<br />

				<div className="row">
					<div className="col-lg-12 text-center">
						<button type="button" onClick={handleCerca} className="btn btn-success">Cerca</button>
					</div>
				</div>

				<hr />
				<div className="row">
					<div className="offset-11 col-md-1">
						<Link to='/automezzi/new' className="btn btn-info btn-block">Aggiungi</Link>
					</div>
				</div>
				<br />
				<TabellaAutomezzi automezzi={automezzi} />

				<div className="row">
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<nav>
							<ul className="pagination pagination-rounded mb-0 justify-content-center">

								<li className="page-item">
									<button className="page-link" onClick={pagine.first ? () => paginate(pagine.first) : null} aria-label="Previous">
										<span aria-hidden="true">&laquo;</span>
									</button>
								</li>
								{
									pagine.before.map((num) => (
										<li key={num} className={"page-item " + ((active === num) ? 'active' : null)}><button className="page-link" onClick={() => paginate(num)}>{num}</button></li>
									))
								}
								<li className={"page-item " + ((active === dati.pagina) ? 'active' : null)}><button className="page-link">{dati.pagina}</button></li>
								{
									pagine.after.map((num) => (
										<li key={num} className={"page-item " + ((active === num) ? 'active' : null)}><button className="page-link" onClick={() => paginate(num)}>{num}</button></li>
									))
								}

								<li className="page-item">
									<button className="page-link" onClick={pagine.last ? () => paginate(pagine.last) : null} aria-label="Next">
										<span aria-hidden="true">&raquo;</span>
									</button>
								</li>
							</ul>
						</nav>
					</div>
					<div className="col-md-4"></div>
				</div>
				<br />
			</div>
		) : null
	);
};

export default ListaAutomezzi;