import React from 'react';
import { Link } from 'react-router-dom';

import './tabella-turni.styles.scss';
import { useDispatch } from 'react-redux';
import { deleteTurno, getTurni } from '../../redux/features/turni/turni.thunk';
import { toast } from 'react-toastify';

const TabellaTurni = ({ turni }) => {

	//console.log(turni);

	const dispatch = useDispatch();

	const del = async (id) => {

		if (window.confirm("Sei sicuro di voler eliminare questo turno?")) {
			const result = await dispatch(deleteTurno(id));

			if (result.error) {
				toast.error(result.payload);
			}
			else {
				toast.success("Turno eliminato con successo");
				dispatch(getTurni(1));
			}
		}
	}

	return (
		<div className="tabella-turni">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<table id="datatable-buttons" className="table dt-responsive nowrap w-100">
								<thead className="table-dark">
									<tr>
										<th scope="col">Servizio</th>
										<th scope="col">Attivita'</th>
										<th className="text-center" scope="col">Data</th>
										<th className="text-center" scope="col">Ora inizio</th>
										<th className="text-center" scope="col">Ora fine</th>
										<th className="text-center" scope="col">Automezzo</th>
										<th className="text-center" scope="col" colSpan="3">Dipendenti</th>
										<th scope="col">Note</th>
										<th className="text-center" scope="col" colSpan="3"></th>
									</tr>
								</thead>
								<tbody>
									{
										(turni) ?
											turni.map((turno) => (
												<tr key={turno.id + " " + turno.otherViewInfo.targaAutomezzo} className={`${turno.id_servizio === 18 ? 'table-primary' : turno.id_servizio === 19 ? 'table-light' : turno.id_servizio === 20 ? 'table-success' : turno.id_servizio === 21 ? 'table-danger' : turno.id_servizio === 22 ? 'table-warning' : turno.id_servizio === 23 ? 'table-info' : turno.id_servizio === 24 ? 'table-dark' : ''}`}>
													<th scope="row">{turno.servizio}</th>
													<td>{turno.attivita}</td>
													<td className="text-center">{new Date(turno.giorno).toLocaleDateString('it-IT', { year: "numeric", month: "numeric", day: "numeric" })}</td>
													<td className="text-center">{turno.oraInizio}</td>
													<td className="text-center">{turno.oraFine}</td>
													<td className="text-center">{(turno.otherViewInfo.targaAutomezzo !== '') ? turno.otherViewInfo.targaAutomezzo : 'NESSUNO'}</td>
													<td colSpan="3">
														{
															turno.otherViewInfo.listaDipendenti.map((val) => {
																return (
																	<span key={val} className="badge badge-outline-dark" style={{ marginRight: 15 }}>{val}</span>
																)
															})
														}
													</td>
													<td>{turno.note}</td>
													<td>
														<Link to={`edit/${turno.id}`} className="btn btn-warning" title='Modifica Turno'><i className="uil uil-edit"></i></Link> &nbsp;
														<button type="button" onClick={() => del(turno.id)} className="btn btn-danger" title='Elimina Turno'><i className="uil uil-times-square"></i></button> &nbsp;
													</td>
												</tr>
											)) : null

									}


								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TabellaTurni;